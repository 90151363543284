
import {useState } from "react";

import './AnswerOptions.scss';
import './media.scss';

const VOID_FIELD = 0;

const AnswerOptions = ({optionId, answerType, inputType, arrayCount, onChange, checkRadio, checkCheckbox}) => {

  const [isCheckbox, setIsCheckbox] = useState(arrayCount?.map((element) => ({id: element.id, content: false}))); //корректное отображение строк таблицы типа tableAnswersCheckbox
  const [isRadio, setIsRadio] = useState(null); //корректное отображение строк таблицы типа tableAnswersRadio
  //в зависимости от типа вопроса обрабатывает выбор пользователя
  const handleChange = (e) => {
    const columnId = Number(e.target.id)
    switch(answerType) {
      case 0:                             
            onChange(optionId, columnId, VOID_FIELD)   
            break; 
      case 1: 
            onChange(optionId, columnId, VOID_FIELD)
            break;
      case 2:
            onChange(VOID_FIELD, VOID_FIELD, e.target.value);
            break;
      case 3:
            onChange(VOID_FIELD, VOID_FIELD, e.target.value);
            break;
      case 4:
            setIsRadio(+e.target.id);
            onChange(optionId, columnId, VOID_FIELD)
            break;
      case 5:
            const updatedCheckedState = isCheckbox.map((item) => item.id === columnId ? {id: item.id, content: !item.content} : item);
            setIsCheckbox(updatedCheckedState);
            if(updatedCheckedState.filter(element => element.content !== null).length !== 0) {
              onChange(optionId, VOID_FIELD, updatedCheckedState);
            }
            else { onChange(optionId, VOID_FIELD, null); }
            break;
      default:
        break;
    }
  };
  
  const checkElement = (index) => {
    switch(answerType) {
      case 0: 
        return(checkRadio.id === optionId);
      case 1: 
        const filteredElementCheckbox = checkCheckbox.filter(element => element.id === optionId)
        return(filteredElementCheckbox.content);
      case 4: return(isRadio === index);
      case 5: return (isCheckbox[index]);
      default:
        break;
    }
  }

	return (
    <>
      {arrayCount?.map((element, index) => (
        <th key={index}>
          <input className={`answer-option__choose-${answerType}`}
                  type={inputType} 
                  placeholder={'Введите ответ...'}
                  id={element.id === -1 ? optionId : element.id}
                  onChange={(e) => {handleChange(e)}}
                  checked={checkElement(element.id === -1 ? optionId : element.id)}
          ></input>
        </th>))}
    </>
	)
}

export default AnswerOptions;