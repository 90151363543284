import * as React from 'react'

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminUserPoint = ({userCredentials, setUserCredentials, acceptChanges, elementStyle, currentRole, setCurrentRole}) => {

    const roleOptions = [
        {roleType: 1, title: "Администратор"},
        {roleType: 2, title: "Контент-менеджер"}
    ]

    const roleChange = (e) => {
        const selectedValue = e.target.value;
        setCurrentRole(Number(selectedValue));
    }

    const credChange = (e) => {
        const currentCredType = Number(e.target.id);
        const currentValue = e.target.value;
        const updatedCredentials = userCredentials.map(item => (
            item.credType === currentCredType ? {credType: currentCredType, credTitle: item.credTitle, content: currentValue} : item
        ))
        setUserCredentials(updatedCredentials)
    }

    return (
        <div className="admin__add" style={{display: elementStyle}}>
            <div className="admin__add__title">
                Данные нового пользователя
            </div>
            {userCredentials && userCredentials?.map((item, i) => (
                <div key={i} className="admin__add__field-block">
                    <div className="admin__add__field-title">
                        {item.credTitle}
                    </div>
                    <input id={item.credType} className="admin__add__field" value={item.content} type='text' placeholder="..."  onChange={(e) => {credChange(e)}}/>
                </div>
            ))}
            <div className="admin__add__field-block">
                <div className="admin__add__field-title">
                    Роль
                </div>
                <select className="question-block__container__select" value={currentRole} onChange={(e) => roleChange(e)}>
                    {roleOptions.map((item, i) => {return <option key={i} value={item.roleType}>{item.title}</option>})}
                </select>
            </div>
            <div className="admin__add__button">
                <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default AdminUserPoint;