
import * as React from 'react'

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminChangePublication = ({publicationTitle, publicationUrl, publicationSection, setPublicationSection,
     setPublicationTitle, setPublicationUrl, elementStyle, acceptChanges, publicationSections}) => {


    const changeFields = (e) => {
        const itemId = e.target.id
        const itemValue = e.target.value;

        if(Number(itemId) === 0)
            setPublicationTitle(itemValue)
        else if(Number(itemId) === 1)
            {setPublicationUrl(itemValue)}
        else if(Number(itemId) === 2)
            {setPublicationSection(itemValue)}
    }

    return (
        <div style={{display: elementStyle}}>
            <div className='admin__article__input-title'>
                Название публикации
            </div>
            <textarea
                id={0}
                className='admin__article__textarea'
                value={publicationTitle} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
                URL публикации
            </div>
            <input
                id={1}
                className='admin__article__input'
                value={publicationUrl} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
                Секция публикации
            </div>
            <select id={2} className="question-block__container__select" value={publicationSection} onChange={(e) => changeFields(e)}>
                {publicationSections.map((item, index) => {return <option key={index} value={item.id}>{item.title}</option>})}
            </select>
            <div className="admin__add__button">
                <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default AdminChangePublication;