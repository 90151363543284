
import {RiArrowGoBackLine, RiPencilFill} from 'react-icons/ri';
import {ImBin} from 'react-icons/im';
import {Link} from 'react-router-dom';

import "./index.scss";

const ChangeButtons = ({itemId, artificialId, removeFunction, cancelFunction, linkAlter, itemStatus}) => {
     return (
        <div id={artificialId} className="change-group">
            <ImBin onClick={() => {removeFunction(itemId)}} className='change-group__icon change-group__icon__bin' style={{display: itemStatus[0]}}/>
            <Link to={linkAlter !== undefined ? linkAlter : '/notFound'}>
                <RiPencilFill className='change-group__icon change-group__icon__alter' style={{display: itemStatus[1]}}/>
            </Link>
            <RiArrowGoBackLine onClick={() => {cancelFunction(itemId)}} style={{display: itemStatus[2]}} className='change-group__icon change-group__icon__cancel'/>
        </div>
     )
 }
 
 export default ChangeButtons;