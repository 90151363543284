
import './SaveButton.scss';

const SaveButton = ({saveChanges, responseText, currentClassName='', isCurrentDisabled=false}) => {

    const checkAndPrepare = () => {
        saveChanges()
    }
     return (
        <div className={`save-button__container__${currentClassName}__${isCurrentDisabled}`}>
            <button className={`save-button__container__${currentClassName}__${isCurrentDisabled}__confirmation`} onClick={() => {checkAndPrepare()}} disabled={isCurrentDisabled}>
                {responseText}
            </button>
        </div>
     )
 }
 
 export default SaveButton;