import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import QuestionSection from '../../../components/UI/Question/QuestionSection';
import SuccessMessage from '../../../components/UI/SuccessMessage';
import LastPageAdmin from '../../../components/UI/Question/LastPageAdmin';
import CheckForm from '../AdminAdd/AdminAddForm/checkForm';
import DownloadImages from '../../../components/UI/Question/DownloadImages';
import SaveButton from '../../../components/UI/SaveButton';
import AddElementButton from '../../../components/UI/AddElementButton';
import CheckBeforePublishing from '../AdminAdd/AdminAddForm/checkBeforePublishing';

import '../AdminPage.scss'
import '../AdminAdd/AdminAdd.scss';

const AdminAlterForm = () => {

    const authUser = useSelector(x => x.auth.user);
    let {formId} = useParams();

    const inputTypes = ['radio','checkbox','text', 'text', 'radio', 'checkbox']

    //дисплеи и классы элементов
    const [elementsDisplay, setElementsDisplay] = useState({messageBlock: 'none', formBlock: 'block'})
    const [suggestionsDisplay, setSuggestionsDisplay] = useState('none');
    const [saveEverythingClassName, setSaveEverythingClassName] = useState('disabled')

    //тексты кнопок
    const [loading, setLoading] = useState('Сохранить'); //текст кнопки сохранения
    const [saveEverythingText, setSaveEverythingText] = useState('Опубликовать анкету')

    //доступность кнопок
    const [buttonSaveIsDisabled, setButtonSaveIsDisabled] = useState(true)
    const [addSuggestionsButton, setAddSuggestionsButton] = useState({isCurrentDisabled: false})
    const [addButtonStatus, setAddButtonStatus] = useState({isCurrentDisabled: false})

    //длины массивов и начальные цифровые значения
    const [imagesNumber, setImagesNumber] = useState({partners: 0, logos: 1})
    const [sectionListLength, setSectionListLength] = useState(0); //отображаемое общее количество секций
    const [sectionUpdatedNumber, setSectionUpdatedNumber] = useState(0); //разница между номером id и отображаемым номером
    const [currentId, setCurrentId] = useState(1); // текущее id секции

    //массив всех секций и массив контента секций
    const [sectionList, setSectionList] = useState([]); //массив всех отображаемых секций
    const [sectionContentList, setSectionContentList] = useState([]) //контент всех отображаемых секций

    //объявление пустых полей анкеты
    const [sectionInformation, setSectionInformation] = useState([])
    const [formTitle, setFormTitle] = useState(""); //название анкеты
    const [formDescription, setFormDescription] = useState(""); //описание анкеты
    const [formPartners, setFormPartners] = useState(null);
    const [formLogo, setFormLogo] = useState(null);
    const [prevFormLogo, setPrevFormLogo] = useState(null);
    const [prevFormPartners, setPrevFormPartners] = useState(null);
    const [prevLastPageSuggestion, setPrevLastPageSuggestion] = useState([]);

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    const questionOrder = (question1, question2) => {
        return question1.number < question2.number ? -1 : (question1.number >= question2.number ? 1 : 0);
    }

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getFormInformationForEditing`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(formId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setFormTitle(data.form.formTitle)
                data.form.description == null ? setFormDescription("") : setFormDescription(data.form.description)
                setPrevFormLogo([{id:  data.formLogo.id, src: data.formLogo.logoContent}])
                setButtonSaveIsDisabled(false)
                setSaveEverythingClassName('active')
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        }).then( fetch(`${process.env.REACT_APP_API_URL}/getFormPartnersForEditing`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(formId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if(data != null) {
                        const partnersArray = data.map((element) => ({
                            id: element.id, 
                            src: element.partnerContent
                        }))
                        setPrevFormPartners(partnersArray)
                    }
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        ).then(fetch(`${process.env.REACT_APP_API_URL}/getFormContent`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(formId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if(data != null) {
                        const allSections = data.map((section) => ({
                            title: section.title,
                            description: section.description,
                            id: section.id,
                            displayType: 'none',
                            questionList: section.content.map((question) => ({
                                id: question.id,
                                title: question.title,
                                answerType: question.questionKind-1,
                                inputType: inputTypes[question.questionKind-1],
                                hasDifferent: question.hasDifferent,
                                isRequired: question.isRequired,
                                option: {
                                    strings: question.strings === undefined ? [''] : question.strings,
                                    columns: question.columns === undefined ? [{id: 0}] : question.columns
                                },
                                number: question.number
                            })).sort(questionOrder)
                        })).sort((a, b) => a.id - b.id)
                        allSections.map((element, i) => (
                            handleChangePrevSectionNumber(element, i+1)
                        ))
                        setSectionListLength(allSections.length)
                        setCurrentId(allSections.length+1)
                        setSectionInformation(allSections)
                    }
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        ).then(
            fetch(`${process.env.REACT_APP_API_URL}/getFormSuggestionsForEditing`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(formId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if(data != null) {
                        const lastPageSuggestions = data.map((element) => ({
                            id: element.id,
                            answerType: 1,
                            inputType: "checkBox",
                            title: element.title,
                        })).sort((a, b) => a.id - b.id)
                        setAddSuggestionsButton({isCurrentDisabled: false, currentDisplay: 'none'})
                        setSuggestionsDisplay('flex')
                        setPrevLastPageSuggestion(lastPageSuggestions)
                    }
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        )
    }, [setAppState])


    const handleChangeSectionNumber = () => {
        setAddButtonStatus({isCurrentDisabled: false, currentDisplay: 'none'})
        setSectionListLength(prev => prev+1)
        setCurrentId(prev => prev+1)
        setSectionList(current => [...current, {id: currentId, currentNumber: currentId-sectionUpdatedNumber, data: null}])
        setSectionContentList(current => [...current, {checkId: null, artificialId: currentId, contentIds: [{id: 0, checkId: null}]}])
    }

    const handleChangePrevSectionNumber = (element, i) => {
        setAddButtonStatus({isCurrentDisabled: false, currentDisplay: 'none'})
        setSectionList(current => [...current, {id: i, currentNumber: i-sectionUpdatedNumber, data: element}])
        setSectionContentList(current => [...current, {checkId: element.id, artificialId: i, contentIds: [{id: 0, checkId: null}]}])
    }

    const constructSectionQuestions = () => {}

    const handleChangeRemoveSection = (removeId) => {
        const changedItemsAfterRemoving = sectionList.map(item => (item.id > removeId ? {id: item.id, currentNumber: (item.currentNumber-1)} : item))
        setSectionList(changedItemsAfterRemoving)
        const updateSectionContentList = sectionContentList.filter(item => item.artificialId !== Number(removeId))
        setSectionContentList(updateSectionContentList)
        setSectionUpdatedNumber(prev => prev+1)
        setSectionListLength(prev => prev-1)
        if(sectionListLength === 1 || removeId === sectionContentList[sectionContentList.length-1].artificialId) {
            setAddButtonStatus({isCurrentDisabled: false, currentDisplay: 'flex'})
        }
    }

    //перевод файлов изображений в base64
    const handleFormImages = (kind, objectToRead) => {
        return objectToRead.map((element) => {
            const reader = new FileReader();            
            let promise = new Promise((resolve, reject) => {
                reader.onloadend = function() {
                    resolve({
                        kind: kind,
                        result: reader.result
                    })
                }
                reader.onerror = function (error) {
                    reject("error")
                };
            })
            reader.readAsDataURL(element.imageValue.item(0));
            return promise;
        })
    }

    //показать блок предложений для респондентов
    const handleChangeRespondentSuggestions = () => {
        setAddSuggestionsButton({isCurrentDisabled: false, currentDisplay: 'none'})
        setSuggestionsDisplay('flex')
    }

    //подготовить данные анкеты к сохранению
    const checkAndPrepare = () => {
        const objectNeedsChanges = CheckForm(formTitle, formLogo) //проверка контента формы на ошибки
        if(objectNeedsChanges.hasObjectsNeedRemake) {
            setLoading("Заполните обязательные поля!")
        }
        else {
            setLoading("Сохранение...")
            let promisesPartners = handleFormImages('partner', formPartners);
            let promisesLogo = handleFormImages('logo', formLogo);
            Promise.all(promisesLogo.concat(promisesPartners)).then(results => {
                const formResponse = {
                    id: Number(formId),
                    titleResponse: formTitle, 
                    descriptionResponse: formDescription,
                    formPartnersResponse: results.filter(e => e.kind === 'partner').map(e => e.result),
                    formAuthor: authUser.id,
                    formLogoResponse: results.filter(e => e.kind === 'logo').map(e => e.result)[0]
                }

                //формирование объекта формы
                fetch(`${process.env.REACT_APP_API_URL}/editForm`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formResponse),
                }).then((response) => {
                    if(response.status == 200) {
                        setLoading("Сохранено")
                    }
                    else {
                        setLoading("Что-то пошло не так")
                    }
                })
            }).catch(error => {
                setLoading("Что-то пошло не так")
            });
        }
    }

    //подготовить анкету к публикации
    const saveEverything = () => {
        if(CheckBeforePublishing(sectionContentList)) {
            fetch(`${process.env.REACT_APP_API_URL}/publishForm`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({id: Number(formId), changingAuthor: authUser.id}),
            }).then((response) => {
                if(response.status == 200) {
                    setSaveEverythingClassName('unvisible')
                    setElementsDisplay({messageBlock: 'block', formBlock: 'none'})
                }
                else {
                    setSaveEverythingText("Что-то пошло не так") 
                }
            })
        }
        else {
            setSaveEverythingText("Сохраните содержимое секций") 
        }
    }


    return (
        <Container fluid className='admin-decorator'>
            <div style={{display: elementsDisplay.formBlock}}>
                <div className='admin-decorator__title'>
                    Редактирование анкеты
                </div>
                <div className='admin__form__input-title'>
                    Заголовок <span style={{color: '#E41D37'}}>(обязательно)</span>
                </div>
                <input id='' className='admin__form__input' type='text' value={formTitle == null ? "" : formTitle} onChange={(e) => {setFormTitle(e.target.value)}}/>
                <div className='admin__form__input-title'>
                    Описание
                </div>
                <textarea className='admin__form__input' type='text' value={formDescription == null ? "" : formDescription} onChange={(e) => {setFormDescription(e.target.value)}}/>
                <DownloadImages marginStyle={{}} resultStatus="(обязательно)" title={'Логотип анкеты'} getImages={setFormLogo} data={prevFormLogo} oneOrMany={imagesNumber.logos}/>
                <DownloadImages marginStyle={{marginBottom: '0vh'}} title={'Загрузка логотипов партнеров'} data={prevFormPartners} getImages={setFormPartners}/>
                <SaveButton 
                    saveChanges={checkAndPrepare} 
                    responseText={loading}
                    currentClassName={'active'}
                    isCurrentDisabled={false}/>
                <AddElementButton 
                    addElement={handleChangeRespondentSuggestions} 
                    addElementText={'Добавить предложения для анкетируемых'} 
                    isCurrentDisabled={addSuggestionsButton.isCurrentDisabled}
                    currentDisplay={addSuggestionsButton.currentDisplay}
                />
                <LastPageAdmin currentStyle={{display: suggestionsDisplay, marginTop: '20px'}} data={prevLastPageSuggestion} currentFormId={Number(formId)}/>
                <AddElementButton 
                    addElement={handleChangeSectionNumber} 
                    addElementText={'Добавить секцию'} 
                    isCurrentDisabled={addButtonStatus.isCurrentDisabled}
                    currentDisplay={addButtonStatus.currentDisplay}/>
                {sectionList && sectionList?.map((block, i) => (
                <QuestionSection key={i} 
                        data={block.data}
                        currentFormId={Number(formId)}
                        sectionId={block.id} 
                        constructSectionQuestions={constructSectionQuestions} 
                        sectionCurrentNumber={block.currentNumber}
                        sectionNumber={sectionListLength} 
                        sectionVisible={block.display}
                        removeSection={handleChangeRemoveSection}
                        handleChangeSectionNumber={handleChangeSectionNumber}
                    />
                ))}
            </div>
            <SaveButton
                    saveChanges={() => {saveEverything()}} 
                    responseText={saveEverythingText}
                    currentClassName={saveEverythingClassName}
                    isCurrentDisabled={buttonSaveIsDisabled}/>
            <SuccessMessage
                        title={'Анкета'} 
                        savedTitle={'опубликована'} 
                        departmentTitle={'Анкеты'} 
                        changedTitle={'анкеты'} 
                        displayStyle={elementsDisplay.messageBlock}/>
        </Container>
    )
}

export default AdminAlterForm;