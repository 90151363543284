
//функция проверки контента формы на ошибки
const CheckBeforePublishing = (checkObject) => {
    let result = true;

    if(checkObject.length !== 0) {
        const firstCondition = checkObject.filter(element => element.checkId === null).length === 0 ? true : false
        const secondCondition = checkObject.filter((section) => section.contentIds.length !== 0).length !== 0 ? true : false
        if(firstCondition && secondCondition) {
            result = checkObject.filter((section) => section.contentIds.filter((element) => element.checkId !== null)).length !== 0 ? true : false
        }
        else {result = false}
    }
    else {result = false}

    return result;
} 
export default CheckBeforePublishing;