
import {Container} from 'react-bootstrap';
import {useState} from 'react';

import AnswerTable from "../AnswerTable/index";

import './AnswerBlock.scss';
import './media.scss';

const AnswerBlock = ({question,  returnAllBlockAnswers}) => {

    const [answers, setAnswers] = useState(null)
    let displayStyle = 'none';
    const[differentValue, setDifferentValue] = useState(null);

    if(question.hasDifferent){
        displayStyle='block';
    }

    //при каждом изменении выбора пользователя возвращает новый выбор блоку AnswerSection 
    const returnArrayChangedAnswersArray= (answersArray) => {
        setAnswers(answersArray)
        returnAllBlockAnswers(question.id, answersArray, differentValue, question.isRequired);
    }
    
    const returnArrayChangedDifferent = (contentDifferent) => {
        setDifferentValue(contentDifferent)
        returnAllBlockAnswers(question.id, answers, contentDifferent, question.isRequired);
    }

    return (
        <Container fluid className='answer-block' id={question.id}>
            <div className={`answer-block__title answer-block__${question.isRequired}`} >
                {question.title}
            </div>
            <div className='answer-block__container__input'>
                <AnswerTable 
                    questionId={question.id} 
                    answerType={question.answerType} 
                    inputType={question.inputType} 
                    strings={question.option.strings} 
                    columns={question.option.columns} 
                    returnArray={returnArrayChangedAnswersArray}/>
            </div>
            <input 
                className="answer-option__choose-diff" 
                style={{display: displayStyle}} 
                placeholder="Другое..." 
                id={question.id} 
                onChange={(e) => {returnArrayChangedDifferent(e.target.value)}}/>
        </Container>
    )
}

export default AnswerBlock;