
import './CustomSwitch.scss';

export const CustomSwitch = ({defineSwitch, isChecked = false}) => {
    return (
        <label className="switch">
            <input type="checkbox" checked={isChecked} className="switch__input" onChange={() => {defineSwitch()}}/>
            <span className="switch__slider-round"></span>
        </label>
    )
}

export default  CustomSwitch;