import * as React from 'react'

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useLayoutEffect, useState} from "react";

import ChangeButtons from '../../../components/UI/ChangeButtons';
import { removeItemFunction } from "../AdminWrapper/RemoveItem";
import { cancelRemovingFunction } from "../AdminWrapper/CancelRemoving";
import cutStrings from '../../../components/UI/CutText';

const AdminMembers = () => {

    const authUser = useSelector(x => x.auth.user);

    const [members, setMembers] = useState([]);
    
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });
    

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllMembers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const membersData = data.map((member) => ({
                    id: member.id, 
                    lastName: member.lastName, 
                    firstName: member.firstName, 
                    patronymicName: member.patronymicName, 
                    workInformation: cutStrings(member.workInfo), 
                    email: member.email, 
                    role: member.role,
                    photo: member.image,
                    educationInformation: cutStrings(member.educationInfo),
                    rewardsInformation: cutStrings(member.rewardsInfo),
                    memberKind: member.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                setMembers(membersData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])


    const removeMember = (currentId) => {
        const alterMembers = members.map(member => member.id !== currentId ? member : {
            id: member.id, 
            lastName: member.lastName, 
            firstName: member.firstName, 
            patronymicName: member.patronymicName, 
            workInformation: member.workInformation, 
            email: member.email, 
            role: member.role,
            photo: member.photo,
            educationInformation: member.educationInformation,
            rewardsInformation: member.rewardsInformation,
            memberKind: ['none', 'block', 'block']
        })
        setMembers(alterMembers)
        removeItemFunction(currentId, 'removeMember', authUser)
    }

    const cancelRemovingMember = (currentId) => {
        const alterMembers = members.map(member => member.id !== currentId ? member : {
            id: member.id, 
            lastName: member.lastName, 
            firstName: member.firstName, 
            patronymicName: member.patronymicName, 
            workInformation: member.workInformation, 
            email: member.email, 
            role: member.role,
            photo: member.photo,
            educationInformation: member.educationInformation,
            rewardsInformation: member.rewardsInformation,
            memberKind: ['block', 'block', 'none']
        })
        setMembers(alterMembers)
        cancelRemovingFunction(currentId, 'cancelRemovingMember', authUser)
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Команда
            </div>
            <div className="admin__btn-group"> 
                <Link to={'/admin/members/newmember'} className='admin__button'>Добавить участника команда</Link>
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th>ФИО</th>
                        <th>Роль</th>
                        <th style={{width: 13+"%"}}>Работа</th>
                        <th style={{width: 13+"%"}}>Образование</th>
                        <th style={{width: 13+"%"}}>Награды</th>
                        <th>E-mail</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {members?.map((member, index) => {
                    return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{member.lastName+" "+member.firstName+" "+member.patronymicName}</td>
                                <td>{member.role}</td>
                                <td>{member.workInformation}</td>
                                <td>{member.educationInformation}</td>
                                <td>{member.rewardsInformation}</td>
                                <td>{member.email}</td>
                                <td style={{textAlign: 'right'}}>
                                    <ChangeButtons 
                                    itemId={member.id} 
                                    removeFunction={removeMember} 
                                    cancelFunction={cancelRemovingMember} 
                                    linkAlter={String(member.id)}
                                    itemStatus={member.memberKind}/>
                                </td>
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminMembers;