
import {Outlet} from "react-router-dom";
import {useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {useRef, useLayoutEffect, useState} from "react";

import AdminHeader from '../../components/navigation/AdminNavigation/AdminHeader';
import AdminSidebar from '../../components/navigation/AdminNavigation/AdminSidebar';

import './AdminDashboardLayout.scss';

const AdminDashboardLayout = () => {
    
    const navigate = useNavigate()
    const authUser = useSelector(x => x.auth.user);

    const PageHeight = useRef(null);
    const [SidebarHeight, setSidebarHeight] = useState(0);
    
    useLayoutEffect(() => {
        setSidebarHeight(PageHeight.current?.offsetHeight);
    }, []);

    if(!authUser) {
        return navigate("/login");
    } else return (
        <div className='admin-container' ref={PageHeight}> 
            <AdminSidebar style={{height:SidebarHeight}}/>
            <div className='admin-container__main-content'>
                <AdminHeader/>
                <Outlet/>
            </div>
        </div>
    )
}

export default AdminDashboardLayout;