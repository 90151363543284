
import * as React from 'react'

import {useState, useLayoutEffect} from 'react';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminChangePublication from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAddPublication = () => {

    const authUser = useSelector(x => x.auth.user);

    const [publicationTitle, setPublicationTitle] = useState("")
    const [publicationUrl, setPublicationUrl] = useState("")
    const [publicationSection, setPublicationSection] = useState(0)
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])

    const [publicationSections, setPublicationSections] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllActivePublicationSections`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setPublicationSection(data[0].id)
                setPublicationSections(data);
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])


    const acceptChanges = () => {
        const publicationResponse = {
            title: publicationTitle, 
            url: publicationUrl, 
            section: Number(publicationSection), 
            author: Number(authUser.id)}

        fetch(`${process.env.REACT_APP_API_URL}/addPublication`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(publicationResponse),
        }).then(result => {
            setElementsDisplays(['none', 'block'])
        }).catch(function(error) {
            console.log(error)
        });
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Добавить публикацию
            </div>
            <AdminChangePublication
                publicationTitle={publicationTitle}
                publicationUrl={publicationUrl}
                publicationSection={publicationSection}
                setPublicationSection={setPublicationSection}
                setPublicationTitle={setPublicationTitle}
                setPublicationUrl={setPublicationUrl}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
                publicationSections={publicationSections}
            />
            <SuccessMessage 
                    title={`Новая публикация`} 
                    savedTitle={'сохранена'} 
                    departmentTitle={'Публикации'} 
                    changedTitle={'публикаций'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAddPublication;