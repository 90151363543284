
const dangerMessage = (pointTitle, pointUrl) => {
    
    const menuPointTitle = document.getElementsByClassName('admin__add__field__title');
    const menuPointUrl = document.getElementsByClassName('admin__add__field__url');

    if(pointTitle) {
        menuPointTitle[0].classList.add('admin__add__field__dangerous');
    }
    if(pointUrl) {
        menuPointUrl[0].classList.add('admin__add__field__dangerous');
    }
}
export default dangerMessage;