import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';

import SuccessMessage from '../../../components/UI/SuccessMessage';

import '../AdminPage.scss'
import '../AdminAdd/AdminAdd.scss';

const AdminAlterEmail = () => {

    let {emailId} = useParams();

    const [emailContent, setEmailContent] = useState('')
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])
    
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getEmailById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(emailId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setEmailContent(data.email)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
         })
    }, [setAppState])


    const handleChangeEmail = (e) => {
        const newValue = e.target.value
        setEmailContent(newValue)
    }

    const acceptChanges = () => {
        const emailResponse = {id: Number(emailId), content: emailContent}
        fetch(`${process.env.REACT_APP_API_URL}/alterEmail`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(emailResponse),
        }).then(result => {
            setElementsDisplays(['none', 'flex'])
        })
    }

    return (
        <Container fluid className='admin-decorator'>
            <div style={{display: elementsDisplays[0]}}>
                <div className='admin-decorator__title'>
                    Изменить e-mail
                </div>
                <div className="admin__add__field-block">
                    <div className="admin__add__field-title">
                        Введите e-mail
                    </div>
                    <input id={emailId} 
                        className="admin__add__field" 
                        value={emailContent} 
                        type='text' 
                        placeholder="..."  
                        onChange={(e) => {handleChangeEmail(e)}}/>
                </div>
                <div className="admin__add__button">
                    <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                        Сохранить
                    </button>
                </div>
            </div>
            <SuccessMessage 
                    title={'E-mail'} 
                    savedTitle={'сохранен'} 
                    departmentTitle={'Контакты сайта'} 
                    changedTitle={'контактов'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAlterEmail;