

const checkAndPrepareSection = (currentSectionAnswers, originalSectionQuestions) => {
  
    let result = {hasQuestionsNeedAnswer: false}

    const requiredQuestions = originalSectionQuestions.filter((question) => question.isRequired === true)
    
    if(currentSectionAnswers === undefined && requiredQuestions.length !== 0){
        result = {arrayOfQuestionsNeedAnswer: requiredQuestions, hasQuestionsNeedAnswer: true}
    }
    else if (currentSectionAnswers === undefined && requiredQuestions.length === 0){
        result = {arrayOfQuestionsNeedAnswer: requiredQuestions, hasQuestionsNeedAnswer: false}
    }
    else {
        const checkedQuestions = currentSectionAnswers.content
        const firstConditionArray = requiredQuestions.map((element) => 
            (checkedQuestions.filter(answer => answer.id === element.id).length === 0 ? element : null)
        )
        const firstCondition = firstConditionArray.filter(element => element !== null).length === 0 ? true : false

        const checkTypeMany = (arrayItem) => {
            const result = arrayItem.filter((element) => element.content !== false).length === 0 ? false : true
            return result;
        }
        const checkTypeText = (value) => {
            const result = value.trim() === '' ? false : true
            return result
        }
        const checkTypeTableOne = (valueArray) => {
            const result = valueArray.filter((element) => element.content === null).length ? false : true
            return result
        }
        const checkTypeTableMany = (valueArray) => { 
            const result = valueArray.filter((element) => element.content === null).length !== 0 ? false : true
            if(result) {
                const checkedValueLength =  valueArray.filter(element => element.content.filter(option => option.content === true).length !== 0)
                const checkCondition = checkedValueLength.length < valueArray.length ? false : true
                return checkCondition
            }
            return result
        }

        const checkByType = (answer) => {
            switch(answer.answerType) {
                case 1:
                    return checkTypeMany(answer.content)
                case 2: 
                    return checkTypeText(answer.content)
                case 3: 
                    return checkTypeText(answer.content)
                case 4: 
                    return checkTypeTableOne(answer.content)
                case 5: 
                    return checkTypeTableMany(answer.content)
                default:
                    return true
            }
        }

        if(firstCondition) {
            const checkedByTypeArray = checkedQuestions.map((answer) => (
                answer.isRequired ? {id: answer.id, checkResult: checkByType(answer.answersList)} :  {id: answer.id, checkResult: true}
            ))
            const secondCondition = checkedByTypeArray.filter(element => element.checkResult === false).length === 0 ? false : true
            const secondConditionArray = checkedByTypeArray.map(element => (element.checkResult === false ? element : null ))
            result = {arrayOfQuestionsNeedAnswer: secondConditionArray, hasQuestionsNeedAnswer: secondCondition}
        }
        else {
            result = {arrayOfQuestionsNeedAnswer: firstConditionArray, hasQuestionsNeedAnswer: true}
        }
    }
    
    return result;
}

export default checkAndPrepareSection;