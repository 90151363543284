
import {Container, Row} from "react-bootstrap";
import {useLayoutEffect, useState} from "react";
import AdvantageElement from '../../components/UI/AdvantageElement/AdvantageElement';
import ArticleCard from "../../components/UI/ArticleCard";

import './MainPage.scss';
import './media.scss';

import advantage1 from '../../images/advantage1.png';
import advantage2 from '../../images/advantage2.png';
import partner1 from '../../images/partner1.png';
import partner2 from '../../images/partner2.png';
import partner3 from '../../images/partner3.png';
import partner4 from '../../images/partner4.png';
import partner5 from '../../images/partner5.png';


const MainPage = () => {

    const partners = [
        {src: partner1},
        {src: partner2},
        {src: partner5},
        {src: partner4},
        {src: partner3}
    ]

    const advantages = [
        {id: 1, src: advantage1, title: 'Разработчики — доктора и кандидаты медицинских наук', description: 'Развиваем трансляционную эпидемиологию и новые подходы к профилактике'},
        {id: 2, src: advantage1, title: 'Национальный и международный опыт', description: 'Опыт ведущих российских научных школ'},
        {id: 3, src: advantage2, title: 'Поддержка ведущего научного фонда России', description: 'Проект РНФ № 22-15-00313'},
    ]

    const [articles, setArticles] = useState([])
    
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllActiveArticles`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const articlesData = data.map((article) => ({
                    id: article.id, 
                    title: article.title,
                    description: article.description,
                    link: article.linkAddress,
                    logo: article.logo
                }))
                setArticles(articlesData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    return (
        <div className='main-page'>
            <Container fluid className='main-page__banner'>
                   <div className='main-page__quote'>
                            Клиническое исследование:
                            <br/> Факторы риска и новые 
                            <br/> вызовы населению на пути 
                            <br/>достижения активного долголетия
                   </div>
            </Container>
            <Container fluid className='main-page__content'>
                <div className='main-page__decorator'>
                    <Container className='main-page__block'>
                        <p className='main-page__block--title'>О проекте</p>
                    </Container>
                    <Container className='main-page__about'>
                        {/* <div className='content-text__paragraf' dangerouslySetInnerHTML={textAboutProject}/> */}
                        <div className='main-page__about--text'>
                            Цель предлагаемого проекта определить (оценить/установить) частоту встречаемости и выраженность «новых» факторов 
                            риска для здоровья и активного долголетия населения на популяционном уровне и в группах высокого риска; 
                            с учетом выявленных закономерностей разработать фокусные информационно-образовательные программы и технологии 
                            грамотной само- и взаимопомощи на основе онлайн и телекоммуникаций
                            В рамках проекта будет впервые реализовано несколько стратегий и методологических подходов для 
                            достижения цели исследования, включая телемедицинские технологии, анализ электронных медицинских карт, 
                            ретроспективное изучение данных медицинской визуализации и статистическую оценку эпидемиологических данных.
                        </div>
                    </Container>
                </div>
            </Container>
            <Container fluid className='main-page__content'>
                <div className='main-page__decorator'>
                    <Container className='main-page__block'>
                        <p className='main-page__block--title'>Наши преимущества</p>
                    </Container>
                    <Container className='main-page__advantages'>
                        <Row className='main-page__block--row'>
                            {advantages?.map((item, index) => (
                                <AdvantageElement item={item} key={index}/>)
                            )}
                        </Row>
                    </Container>
                </div>
            </Container>
            <Container fluid className='main-page__content'>
                <div className='main-page__decorator'>
                    <Container className='main-page__block'>
                        <p className='main-page__block--title'>Полезные материалы</p>
                    </Container>
                    <Container className='main-page__resources'>
                        <Row  className='main-page__block--row'>
                            {articles?.map((item, index) => (
                                <ArticleCard key={index} item={item} linkAlter={item.link} />)
                            )}
                        </Row>
                    </Container>
                </div>
            </Container>
            <Container fluid className='main-page__content' id={'partners'}>
                <div className='main-page__decorator'>
                    <Container className='main-page__block'>
                        <p className='main-page__block--title'>Партнеры</p>
                    </Container>
                    <Container className='main-page__partners'>
                        <Row className='main-page__partners--row'>
                            {partners.map((partner, index) => ( 
                                <img key={index} src={partner.src} className='main-page__partners--image' alt="partner"/>)
                            )}
                        </Row>
                    </Container>
                </div>
            </Container>
        </div>
    )
}

export default MainPage;