import * as React from 'react';
import {useState, useLayoutEffect} from "react";
import { Container} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import '../SecondLevel.scss';

const PublicationSections = () => {

    const [publicationsSections, setPublicationSections] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllActivePublicationSections`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setPublicationSections(data);
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const changeTitle = (sectionIndex) => {
        const choosen = publicationsSections[sectionIndex];
        localStorage.setItem('choosenPublicationSection', JSON.stringify(choosen.title));
    }
    
    return (
        <div className='second-level'>
            <Container fluid className='second-level__content'>
                <Container className='second-level__title'>
                    <div className='second-level__title--text'>
                        Публикации
                    </div> 
                </Container>
                <Container className='second-level__publication--sections'>
                    {publicationsSections?.map((section, index) => (
                        <Link to={`/publicationSections/${section.id}`} key={index} className='second-level__publication--sections--section' onClick={()=>changeTitle(index)}>
                            <div className='second-level__publication--sections--index'>{index+1+'.'}</div>
                            <div className='second-level__publication--sections--title'>{section.title}</div>
                        </Link>))}
                </Container>
            </Container>
        </div>
        
    )
}

export default PublicationSections;