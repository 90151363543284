import * as React from 'react';
import {useState, useLayoutEffect} from "react";
import {useParams} from 'react-router-dom';
import { Container} from 'react-bootstrap';
import '../SecondLevel.scss';

const Publication = () => {

    let {publicationSectionId} = useParams();

    const [publications, setPublications] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllActivePublicationsForSection`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(publicationSectionId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const publicationsData = data.map((publication) => ({
                    id: publication.id, 
                    title: publication.content,
                    link: publication.linkAddress,
                }))
                setPublications(publicationsData);
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    return (
        <div className='second-level'>
            <Container fluid className='second-level__content'>
                <Container className='second-level__title'>
                    <div className='second-level__title--text'>
                        {JSON.parse(localStorage.getItem('choosenPublicationSection'))}
                    </div> 
                </Container>
                <Container className='second-level__publications'>
                    {publications.map((publication, index) => (
                        <div key={index} className='second-level__publications--item'>
                            <div className='second-level__publications--index'>{index+1+'.'}</div>
                            <div className='second-level__publications--title'>{publication.title}</div>
                        </div>))}
                </Container>
            </Container>
        </div>
        
    )
}

export default Publication;