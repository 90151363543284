
import {Container} from 'react-bootstrap';
import {useLayoutEffect, useState} from "react";

import './Footer.scss';

const Footer = () => {

    const [emails, setEmails] = useState([])
    const [phones, setPhones] = useState([])
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
      });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getActiveEmails`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify('test'),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedEmails = data.map((item) => ({
                    id: item.id, 
                    content: item.email
                }))
                setEmails(changedEmails)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
        fetch(`${process.env.REACT_APP_API_URL}/getActivePhones`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify('test'),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedPhones = data.map((item) => ({
                    id: item.id, 
                    content: item.phone
                }))
                setPhones(changedPhones)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    return (
        <Container fluid className="footer">
            <div className="footer-decorator">
                <div className='site-name'>
                        Факторы риска в условиях пандемии COVID-19: 
                        <br/>
                        новые вызовы населению на пути достижения активного долголетия
                </div>
                <div className='footer-cell'>
                <div className='local-title'>
                    Контакты:
                </div>
                <div className='footer-text'>
                    Почта: 
                    <div className="footer-text__contact">
                        {emails && emails?.map((item, i) => (
                        <div key={i}>
                            {item.content}
                        </div>))}
                    </div>
                </div>
                <div className='footer-text'>
                    Телефон: 
                    <div className="footer-text__contact">
                        {phones && phones?.map((item, i) => (
                        <div key={i}>
                            {item.content}
                        </div>))}
                    </div>
                </div>
                </div>
            </div>
        </Container>
    )
}

export default Footer;