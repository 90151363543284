
import {Col} from "react-bootstrap";
import './ArticleCard.scss'

export const ArticleCard = ({item, linkAlter='/notFound'}) => {

	return (
        <Col xl={3} lg={4} md={4} sm={6} xs={12} className="card--wrapper">
            <a href={linkAlter} target="_blank" rel="noopener noreferrer" className="card">
                <div className="card__top">
                    <div className="card__image--wrap">
                        <img className="card__image" src={item?.logo} alt="tea"/>
                    </div>
                    <div className="card__name">
                        {item?.title}
                    </div>
                </div>
                <div className="card__text">
                    <div className="card__text--description">
                        {item?.description}
                    </div>
                </div>
            </a>
        </Col>
	)
}

export default ArticleCard