
export const cancelRemovingFunction = (currentId, fetchUrl, currentAuthor) => {

    fetch(`${process.env.REACT_APP_API_URL}/${fetchUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({id: Number(currentId), changingAuthor: Number(currentAuthor.id)}),
    }).then(result => {
        if(result.status !== 200) {
            console.log(result)
        }
    }).catch(function(error) {
        console.log(error)
    });
}