const dangerMessage = (objectNeedsChanges) => {
    const userFields = document.getElementsByClassName('admin__add__field');

    objectNeedsChanges.forEach(element => {
        for(const userField of userFields) {
           if(Number(userField.id) === Number(element.credType)) {
                userField.classList.add('admin__add__field__dangerous');
           }
        }
      })
}
export default dangerMessage;