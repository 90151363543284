import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useLayoutEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {removeItemFunction} from "../AdminWrapper/RemoveItem";
import {cancelRemovingFunction} from "../AdminWrapper/CancelRemoving";
import ChangeButtons from "../../../components/UI/ChangeButtons";
import splitTime from '../../../components/UI/Functions/splitTime';

const AdminUsers = () => {

    const authUser = useSelector(x => x.auth.user);

    const [usersContent, setUsersContent] = useState([]);

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllUsersResponse`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify('test'),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedData = data.map((user) => ({
                    id: user.id, 
                    role: user.role, 
                    firstName: user.firstName, 
                    lastName: user.lastName,
                    patronymicName: user.patronymicName,
                    time: splitTime(user.timePoint),
                    userKind: user.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']}))
                setUsersContent(changedData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const removeUser = (currentId) => {
        const alterUsers = usersContent.map(user =>user.id !== currentId ? user : {
            id: user.id, 
            role: user.role, 
            firstName: user.firstName, 
            lastName: user.lastName,
            patronymicName: user.patronymicName,
            time: user.time,
            userKind: ['none', 'block', 'block']
        })
        setUsersContent(alterUsers)
        removeItemFunction(currentId, 'removingUser', authUser)
    }

    const cancelRemovingUser = (currentId) => {
        const alterUsers = usersContent.map(user =>user.id !== currentId ? user : {
            id: user.id, 
            role: user.role, 
            firstName: user.firstName, 
            lastName: user.lastName,
            patronymicName: user.patronymicName,
            time: user.time,
            userKind: ['block', 'block', 'none']
        })
        setUsersContent(alterUsers)
        cancelRemovingFunction(currentId, 'cancelRemovingUser', authUser)
    }


    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Пользователи
            </div>
            <div className="admin__btn-group"> 
                <Link to={'/admin/users/newuser'} className='admin__button'>Добавить пользователя</Link>
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th>ФИО</th>
                        <th>Роль</th>
                        <th>Дата создания</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {usersContent && usersContent?.map((item, i) => {
                    return <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item.lastName + " " + item.firstName + " " +  item.patronymicName}</td>
                                <td>{item.role}</td>
                                <td>{item.time}</td>
                                <td>
                                    <ChangeButtons 
                                    itemId={item.id} 
                                    artificialId={i === item.id ? (i+1)*100 : i}
                                    removeFunction={removeUser} 
                                    cancelFunction={cancelRemovingUser} 
                                    linkAlter={String(item.id)}
                                    itemStatus={item.userKind}/>
                                </td>
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminUsers;
