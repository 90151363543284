import * as React from 'react'

import DownloadImages from '../../../../components/UI/Question/DownloadImages';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminChangeMember = ({prevPhoto, lastName, firstName, patronymicName, role, 
    email, workInformation, educationInformation, rewardsInformation, setPhoto, setLastName, setFirstName, setPatronymicName,
    setRole, setEmail, setWorkInformation, setEducationInformation, setRewardsInformation,
    elementStyle, acceptChanges}) => {

    const changeFields = (e) => {
        const itemId = e.target.id
        const itemValue = e.target.value;

        if(Number(itemId) === 1)
            setRole(itemValue)
        else if(Number(itemId) === 2)
            {setLastName(itemValue)}
        else if(Number(itemId) === 3)
            {setFirstName(itemValue)}
        else if(Number(itemId) === 4)
            {setPatronymicName(itemValue)}
        else if(Number(itemId) === 5)
            {setEmail(itemValue)}
        else if(Number(itemId) === 6)
            {setWorkInformation(itemValue)}
        else if(Number(itemId) === 7)
            {setEducationInformation(itemValue)}
        else if(Number(itemId) === 8)
            {setRewardsInformation(itemValue)}
    }

    return (
        <div style={{display: elementStyle}}>
            <DownloadImages marginStyle={{}} resultStatus="(по желанию)" title={'Фото члена команды'} data={prevPhoto} oneOrMany={1} getImages={setPhoto}/>
            <div className='admin__article__input-title'>
                Роль в команде
            </div>
            <input
                id={1}
                className='admin__article__input'
                value={role} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
                Фамилия
            </div>
            <input
                id={2}
                className='admin__article__input'
                value={lastName} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
                Имя
            </div>
            <input
                id={3}
                className='admin__article__input'
                value={firstName} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
                Отчество
            </div>
            <input
                id={4}
                className='admin__article__input'
                value={patronymicName} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
                E-mail
            </div>
            <input
                id={5}
                className='admin__article__input'
                value={email} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
               Информация о работе
            </div>
            <textarea
                id={6}
                className='admin__article__textarea'
                value={workInformation} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
               Информация об образовании
            </div>
            <textarea
                id={7}
                className='admin__article__textarea'
                value={educationInformation} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className='admin__article__input-title'>
               Информация о наградах
            </div>
            <textarea
                id={8}
                className='admin__article__textarea'
                value={rewardsInformation} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className="admin__add__button">
                <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default AdminChangeMember;