
import {Container} from 'react-bootstrap';
import {BsBoxArrowRight} from 'react-icons/bs'
import {useDispatch} from 'react-redux';

import {authActions} from '../../../../_store';

import './AdminHeader.scss';

const AdminHeader = () => {

    const dispatch = useDispatch();
    
    function goOut() {
        return dispatch(authActions.logout());
    }
    
    return (
        <Container fluid className = "admin-header">
            <div className="admin-header__go-out" onClick={() => {goOut()}}>
                <div className='admin-header__go-out__text'>
                    Выход
                </div>
                <BsBoxArrowRight/>
            </div>
        </Container>
    )
}

export default AdminHeader;