
import { useEffect } from "react";
import {BsXSquare} from "react-icons/bs"

import "./ModalWindow.scss"

const ModalWindow = ({ isVisible = 'none', title, content, footer, onClose }) => {

    const keydownHandler = ({ key }) => {
      switch (key) {
        case 'Escape':
          onClose();
          break;
        default:
      }
    };
  
    useEffect(() => {
      document.addEventListener('keydown', keydownHandler);
      return () => document.removeEventListener('keydown', keydownHandler);
    });
  
    return (
      <div className="modal" onClick={onClose} style={{display: isVisible}}>
        <div className="modal__dialog" onClick={e => e.stopPropagation()}>
          <div className="modal__header">
            <div className="modal__title">{title}</div>
            <BsXSquare className="modal__close" onClick={onClose}/>
          </div>
          <div className="modal__body">
            <div className="modal__content">
              <p>{content}</p>
            </div>
          </div>
          <div className="modal__footer">
            {footer}
          </div>
        </div>
      </div>
    );
  }
  export default ModalWindow;
