
import {Container} from 'react-bootstrap';
import { useState} from 'react';

import AnswerBlock from "../AnswerBlock";

import './AnswerSection.scss';
import './media.scss';

const AnswerSection = ({section, returnAllSectionArrays, style}) => {

    //массив всех ответов в секции
    const [sectionArray, setSectionArray] = useState([])

    //при каждом изменении выбора пользователя возвращает новый выбор блоку 
    const returnAllBlockAnswers = (questionId, arrayAnswers, contentDifferent, isAnswerRequired) => {
        const checkCondition = (sectionArray.filter(element => element.id === questionId)).length !== 0
        const updateElement = {id: questionId, 
            answersList: arrayAnswers, 
            different: contentDifferent, 
            isRequired: isAnswerRequired}
        if(checkCondition) {
            const updatedSectionArray = sectionArray.map((question) => (
                question.id === questionId ?  updateElement : question
            ))
            setSectionArray(updatedSectionArray)
            returnAllSectionArrays(section.id, updatedSectionArray)
        }
        else {
            setSectionArray((current) => [...current, updateElement])
            returnAllSectionArrays(section.id, sectionArray.concat([updateElement]))
        }
    }

    return (
        <Container fluid className='answer-section' style={style} id={section.id}>
            <div className='answer-section__title'>
                {section.title}
            </div>
            <div className='answer-section__description'>
                {section.description}
            </div>
            <div className='answer-section__content'>
                {section.questionList?.map((question, i) => (
                    <AnswerBlock 
                        key={i} 
                        question={question}  
                        returnAllBlockAnswers={returnAllBlockAnswers}/>))}
            </div>
        </Container>
    )
}

export default AnswerSection;

