import * as React from 'react';
import {useRef, useState, useLayoutEffect} from "react";
import {Container, Row} from 'react-bootstrap';
import FormCard from '../../../components/UI/FormCard';
import cutStrings from '../../../components/UI/CutText';
import '../SecondLevel.scss';

const AllForms = () => {
    const [pageText, setPageText] =useState({__html: ''})
    const [forms, setForms] = useState([])
    const blockHeight = useRef('400');

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getTextByTitleAsHtml`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({title: 'Наши анкеты', pageTitle: 'Анкеты'}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setPageText({__html: data.textContent})
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
        fetch(`${process.env.REACT_APP_API_URL}/getActiveForms`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(""),
      }).then((response) => {
          response.text().then(text => {
              const data = text && JSON.parse(text)
              const changedData = data.map((element) => ({
                link: '/forms/'+ element.form.id,
                title: cutStrings(element.form.formTitle),
                src: element.formLogo.logoContent,
                description: cutStrings(element.form.description),
                date: element.form.creationTime.split('T', 1),
              }))
            setForms(changedData)
          });
          setAppState({ loading: false, repos: response});
      }).catch(function(error) {
        setForms([])
      })
    }, [setAppState])

    
    return (
        <div className='second-level'>
            <Container fluid className='second-level__content'>
                <Container className='second-level__title'>
                    <div className='second-level__title--text'>
                        Наши анкеты
                    </div> 
                </Container>
                <Container className='second-level__description'>
                    <p className='second-level__description--text' dangerouslySetInnerHTML={pageText}/>
                </Container>
                <Container className='second-level__forms'>
                    <Row className='second-level__forms__block' ref={blockHeight}>
                        {forms && forms?.map((form, i) => (
                            <FormCard key={i} item={form} linkAlter={form.link}/>
                        ))}
                    </Row>
                </Container>
            </Container>
        </div>
        
    )
}

export default AllForms;