
import * as React from 'react'

import {useState} from 'react';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminChangePublicationSection from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAddPublicationSection = () => {

    const authUser = useSelector(x => x.auth.user);
    const [sectionTitle, setSectionTitle] = useState("")
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])

    const acceptChanges = () => {
        const publicationSectionResponse = {
            title: sectionTitle, 
            author: Number(authUser.id)}
        
        fetch(`${process.env.REACT_APP_API_URL}/addPublicationSection`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(publicationSectionResponse),
        }).then(result => {
            setElementsDisplays(['none', 'block'])
        }).catch(function(error) {
            console.log(error)
        });
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Добавить секцию публикаций
            </div>
            <AdminChangePublicationSection
                sectionTitle={sectionTitle}
                setSectionTitle={setSectionTitle}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
            />
           <SuccessMessage 
                    title={'Новая секция публикаций'} 
                    savedTitle={'сохранена'} 
                    departmentTitle={'Публикации'} 
                    changedTitle={'публикаций'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAddPublicationSection;