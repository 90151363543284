
const checkAndPrepareLastPageData = (lastPageData, checkSum) => {
    let result = false;
    const choosenCheckboxes = lastPageData.choosesArray.filter((checkbox) => (
        (checkbox.content===true)
    ))
    const arrayOfNotAnsweredRequiredQuestion = lastPageData.dataArray.filter((question) => (
        (question.content===null || question.content==='')
    ))

    if(arrayOfNotAnsweredRequiredQuestion.length === checkSum && choosenCheckboxes.length > 0) {
        result = true;
    }
    if(lastPageData.condition === false) {
        result = true;
    }

    return result;
}

export default checkAndPrepareLastPageData;