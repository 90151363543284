
const dangerMessage = (questionNeedRemake) => {

    const questions = document.getElementsByClassName('question-block__alert-message');

    for(const question of questions) {
        if(question.id == questionNeedRemake.id) {
            question.classList.add('question-section__alert-message__show');
        }
    }

}
export default dangerMessage;