
import {useState} from 'react';

import './LastPage.scss';
import './media.scss';

const LastPage = ({questionList, style, returnPersonData}) => {

    const[choosenCondition, setChoosesCondition] = useState([]);
    const[choosenInputs, setChoosesInputs] = useState(new Array(2).fill({id: null, content: null}));
    const[isRequired, setIsRequired] = useState(false);
    const[hasPermission, setHasPermission] = useState(false);

    const handleChangeChoose = (choosenId) => {
        const checkCondition = (choosenCondition.filter(element => element.id ===choosenId)).length !== 0
        if(checkCondition) {
            const updatedCheckedState = choosenCondition.map((item) => item.id === choosenId ? {id: item.id, content: !item.content} : item);
            setIsRequired(updatedCheckedState.filter(element => element.content === true).length !== 0);
            setChoosesCondition(updatedCheckedState);
            returnPersonData(updatedCheckedState, choosenInputs, hasPermission);
        }
        else {
            setIsRequired(true);
            setChoosesCondition((current) => [...current, {id: choosenId, content: true}])
            returnPersonData(choosenCondition.concat([{id: choosenId, content: true}]), choosenInputs, hasPermission);
        }
    };

    const handleChangeInput = (position, e) => {
        const updatedInputState = choosenInputs.map((item, index) => index === position ? {id: position, content: e.target.value} : item)
        setChoosesInputs(updatedInputState);
        returnPersonData(choosenCondition, updatedInputState, hasPermission);
    };
 
    const handlePermission = () => {
        setHasPermission(prev => !prev)
        returnPersonData(choosenCondition, choosenInputs, !hasPermission);
    }

    const checkElement = (idValue) => {
        const checkElementValue = choosenCondition.filter(element => element.id === idValue)
        if(checkElementValue.length !== 0) {
            return checkElementValue[0].content
        }
        return false
    }

    const downloadFile = () => {
        fetch(`${process.env.REACT_APP_API_URL}/getAgreeText`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(""),
        }).then(response => {
            if ((response.ok === true) & (response.status === 200)) {
                return response.blob();
            }
        })
        .then(data => {
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Согласие на обработку персональных данных.docx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    return (
        <div>
            <div className='last-page' style={style}>
                <div className='last-page__title'>
                    Обратите внимание на информацию
                </div>
                <div className='last-page__block'>
                    {questionList?.map((item, i) => (
                        <div key={i} className='last-page__block__item'>
                            <input 
                                type={item.inputType} 
                                className={`answer-option__choose-${item.answerType}`} 
                                id={item.id}
                                onChange={() => {handleChangeChoose(item.id)}}
                                checked={checkElement(item.id)}/>
                                {item.title}
                        </div>
                    ))}
                    <div className={`last-page__block__data last-page__block__data__${isRequired}`}>
                        <input className="last-page__block__item__input" placeholder='Email' onChange={(e) => {handleChangeInput(0, e)}}/>
                    </div>
                    <div className={`last-page__block__data last-page__block__data__${isRequired}`}>
                        <input className="last-page__block__item__input" placeholder='Телефон' onChange={(e) => {handleChangeInput(1, e)}}/>
                    </div>
                    <div className='last-page__block__item'>
                            <input 
                                type={'checkbox'} 
                                className={`answer-option__choose-1`} 
                                onChange={() => {handlePermission()}}
                            />
                            <div className='answer-block__true' onClick={() => downloadFile()}>
                                Я согласен на обработку моих <span className='last-page__block__agree'>персональных данных</span>
                            </div>
                        </div>
                </div>
            </div>
            <div className='last-page__block__dangerous-message'>
                Пожалуйста, заполните обязательные поля!
            </div>
    </div>
    )
}

export default LastPage;

