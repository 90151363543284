
import * as React from 'react'

import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import checkPoint from './checkMenuPoint';
import dangerMessage from './dangerMessage';
import removeDangerMessage from './removeDangerMessage';
import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminMenuPoint from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAlterMenuPoint = () => {
    let { pointId } = useParams();
    const authUser = useSelector(x => x.auth.user);
    const[pointTitle, setPointTitle] = useState("")
    const [pointUrl, setPointUrl] = useState("")
    const [elementsDisplays, setElementsDisplays] = useState(['flex', 'none'])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getMenuPointById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(pointId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setPointTitle(data.pointTitle)
                setPointUrl(data.linkAddress)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const acceptChanges = () => {
        removeDangerMessage();
       const checkResult = checkPoint(pointTitle, pointUrl)
       if(checkResult.titleResult === true || checkResult.urlResult === true) {
            dangerMessage(checkResult.titleResult, checkResult.urlResult)
       }
       else { 
         const pointResponse = {id: Number(pointId), title: pointTitle, urlPoint: pointUrl, pointAuthor: authUser.id}
         fetch(`${process.env.REACT_APP_API_URL}/alterMenuPointResponse`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pointResponse),
        }).then(result => {
            setElementsDisplays(['none', 'block'])
        })
       }
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Изменить пункт меню
            </div>
            <AdminMenuPoint
                pointTitle={pointTitle}
                pointUrl={pointUrl}
                setPointTitle={setPointTitle}
                setPointUrl={setPointUrl}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
            />
            <SuccessMessage 
                title={'Пункт меню'} 
                savedTitle={'сохранен'} 
                departmentTitle={'Меню'} 
                changedTitle={'пункта меню'} 
                displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAlterMenuPoint;