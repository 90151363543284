import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState} from "react";

import checkUser from './checkNewUser';
import dangerMessage from './dangerMessage';
import removeDangerMessage from './removeDangerMessage';
import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminUserPoint from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAddUser = () => {

    const credentials = [
        {credType: 0, credTitle: "Фамилия", content: ""},
        {credType: 1, credTitle: "Имя", content: ""},
        {credType: 2, credTitle: "Отчество", content: ""},
        {credType: 3, credTitle: "Логин", content: ""},
        {credType: 4, credTitle: "Пароль", content: ""},
    ]

    const [currentRole, setCurrentRole] = useState(1);
    const [userCredentials, setUserCredentials] = useState(Array.from(credentials))
    const [elementsDisplays, setElementsDisplays] = useState(['flex', 'none'])

    const acceptChanges = () => {
        removeDangerMessage();
       const checkResult = checkUser(userCredentials)
       if(checkResult.result) {
            dangerMessage(checkResult.checkedArray)
       }
       else {
         const userResponse = {credentials: userCredentials, role: currentRole}
         console.log(userResponse)
         fetch(`${process.env.REACT_APP_API_URL}/addNewUser`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userResponse),
        }).then(result => {
            console.log(result)
            setElementsDisplays(['none', 'flex'])
        })
       }
    }

    return (
        <Container fluid className='admin-decorator'>
            <div>
                <div className='admin-decorator__title'>
                    Новый пользователь
                </div>
                <AdminUserPoint 
                    userCredentials={userCredentials}
                    setUserCredentials={setUserCredentials} 
                    acceptChanges={acceptChanges} 
                    elementStyle={elementsDisplays[0]}
                    currentRole={currentRole}
                    setCurrentRole={setCurrentRole}
                />
                <SuccessMessage 
                        title={'Пользователь'} 
                        savedTitle={'сохранен'} 
                        departmentTitle={'Пользователи'} 
                        changedTitle={'профиля пользователя'} 
                        displayStyle={elementsDisplays[1]}/>
            </div>
        </Container>
    )
}

export default AdminAddUser;