
import {FiCircle, FiSquare} from 'react-icons/fi';
import {BsXLg} from 'react-icons/bs';
import {useState, useEffect} from 'react';

export const QuestionChooseOption = ({id, type, blockWidth, classType, checkFunction, checkRemoveWord, removeAnswer, data=null}) => {

    const [prevValue, setPrevValue] = useState(null)
    let element = null; //декоративное обозначение типа вопроса сбоку от опции
    let inputDisplay = 'none'; //стиль input
    let deleteDisplay = 'block'; //стиль кнопки "удалить"
  
    //передача данных родтельскому элементу при заполнении опции текстом
    const handleChange = (e) => {
        setPrevValue(e.target.value)
        checkFunction(e.target.value, id);
    }

    useEffect(() => {
        if(data !== undefined) {
            setPrevValue(data)
            checkFunction(data, id);
        }
    }, [data])

    if((type===0 || type===4) & classType!=='input__diff') {
        inputDisplay = 'block';
        element = <FiCircle className="question-type__choose"/>;
    }
    else if((type===1 || type===5) & classType!=='input__diff') {
        inputDisplay = 'block';
        element = <FiSquare className="question-type__choose"/>;
    } 
    else if(type===2 || type===3) {
        deleteDisplay = 'none';
    }

	return (
        <div className="question-block__string" >
            <div className={`question-type__${classType}`}>
                {element}
                <input 
                    className='question-type__input' 
                    type='text' 
                    id={id}
                    value={prevValue == null ? "" : prevValue}
                    placeholder="Добавить вариант..." 
                    onChange={(e) => {handleChange(e)}}
                    style={{display: inputDisplay, width: blockWidth+'px'}}
                />
            </div>
            <BsXLg className="question-type__table__icon" style={{display: deleteDisplay}} onClick={(e) => {removeAnswer(e, id, checkRemoveWord, classType)}}/> 
        </div>
	)
}

export default QuestionChooseOption;