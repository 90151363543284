
import * as React from 'react'

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminChangePublicationSection = ({sectionTitle, setSectionTitle, elementStyle, acceptChanges}) => {
    
    const changeFields = (e) => {
        const itemValue = e.target.value;
        setSectionTitle(itemValue)
    }

    return (
        <div style={{display: elementStyle}}>
            <div className='admin__article__input-title'>
                Название секции
            </div>
            <textarea
                className="admin__add__field admin__add__field__middle" 
                value={sectionTitle} 
                type='text' 
                placeholder="..."  
                onChange={(e) => {changeFields(e)}}/>
            <div className="admin__add__button">
                <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default AdminChangePublicationSection;