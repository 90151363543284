
import {Container, Row, Col} from 'react-bootstrap';
import {useState, useLayoutEffect} from "react";
import {HashLink } from 'react-router-hash-link';
import {Link} from 'react-router-dom';
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai';

import logo from '../../../images/logo.png';

import './TopNavigation.scss';
import './media.scss'

const TopNavigation = () => {

    const [currentActiveUrl, setCurrentActiveUrl] = useState("")
    const [isShown, setIsShown] = useState(true);
    const [menuContent, setMenuContent] = useState([]);

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
      });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getCurrentActiveFormUrl`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify('test'),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setCurrentActiveUrl(data)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        }).then(
            fetch(`${process.env.REACT_APP_API_URL}/getMenuPointsActive`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({}),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    const points = data.map((item) => ({
                        id: item.id,
                        title: item.pointTitle,
                        link: item.linkAddress
                    }))
                    const sortedPoints = points.sort((a,b) => a.id - b.id);
                    setMenuContent(sortedPoints)
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        )
    }, [setAppState])


    const handleClick = () => {
      setIsShown(current => !current);
    };

    return (
        <div>
            <Container fluid className = "top-navbar">
            <Row className = "top-navbar--row">
                <div className = "top-navbar--wrapper">
                    <HashLink smooth to="/#main" className="top-navbar--logo">
                        <img src={logo} className="top-navbar--logo-image" alt="a"/>
                    </HashLink>
                    <div className="top-navbar__links">
                        <div className="top-navbar__links--large">
                            {menuContent?.map((item, index) => (
                                <HashLink key={index} to={item.link}>{item.title}</HashLink>
                            ))}
                        </div>
                        <Link to={`/forms/${currentActiveUrl}`} className="top-navbar__links__pull-btn">
                            Анкетирование
                        </Link>
                        <div className="top-navbar__links--small">
                            <div className="top-navbar__links__navigation-btn" style={{display: isShown ? 'flex' : 'none'}} onClick={handleClick}>
                                <AiOutlineMenu/>
                            </div>
                            <div className="top-navbar__links__navigation-btn" style={{display: isShown ? 'none' : 'flex'}} onClick={handleClick}>
                                <AiOutlineClose />
                            </div>
                        </div>
                    </div>
                </div> 
            </Row>
        </Container>
        <Container  fluid className = "top-navbar__links__dropdown" style={{display: isShown ? 'none' : 'flex'}}>
            <Row className = "top-navbar__links__dropdown__row">
                <div className = "top-navbar__links__dropdown__row--wrapper">
                    {menuContent?.map((item, index) => (
                        <HashLink key={index} to={item.link}>{item.title}</HashLink>
                    ))}
                </div>
            </Row>
        </Container>
        </div>
    )
}

export default TopNavigation;