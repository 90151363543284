
const addDangerousOutline = (dangerousAnswers=[]) => {

let result = {
   id: Number.MAX_SAFE_INTEGER
}
const arrayOfAllQuestions = document.getElementsByClassName('answer-block');
const arrayLength = arrayOfAllQuestions.length;

   dangerousAnswers.forEach(element => {
      if(element !== null) {
         let i = 0;
         while(i < arrayLength) {
            if(Number(arrayOfAllQuestions[i].id) === element.id) {
               if(result.id > arrayOfAllQuestions[i].id) {
                  result = arrayOfAllQuestions[i]
               }
               arrayOfAllQuestions[i].classList.add('answer-block__dangerous');
            }
            i++;
         }
      }
   })
   return result;
}

export default addDangerousOutline;