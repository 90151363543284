import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState} from 'react';
import {useSelector} from 'react-redux';

import SuccessMessage from '../../../../components/UI/SuccessMessage';

import '../../AdminPage.scss'
import '../AdminAdd.scss';
import AdminChangeMember from '.';

const AdminAddMember = () => {

    const authUser = useSelector(x => x.auth.user);

    const [role, setRole] = useState('')
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [patronymicName, setPatronymicName] = useState('')
    const [photo, setPhoto] = useState(null)
    const [email, setEmail] = useState('')
    const [workInformation, setWorkInformation] = useState('')
    const [educationInformation, setEducationInformation] = useState('')
    const [rewardsInformation, setRewardsInformation] = useState('')

    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])

    //перевод файлов изображений в base64
    const handleMemberPhoto = (objectToRead) => {
        return objectToRead.map((element) => {
            const reader = new FileReader();            
            let promise = new Promise((resolve, reject) => {
                reader.onloadend = function() {
                    resolve({
                        result: reader.result
                    })
                }
                reader.onerror = function (error) {
                    reject("error")
                };
            })
            reader.readAsDataURL(element.imageValue.item(0));
            return promise;
        })
    }

    const acceptChanges = () => {

        let promisedPhoto = handleMemberPhoto(photo);
        Promise.all(promisedPhoto).then(results => {
            const memberResponse = {
                role: role, 
                lastName: lastName,
                firstName: firstName,
                patronymicName: patronymicName,
                email: email,
                workInformation: workInformation,
                educationInformation: educationInformation,
                rewardsInformation: rewardsInformation,
                photo: results[0].result,
                author: Number(authUser.id)
            }
        
            fetch(`${process.env.REACT_APP_API_URL}/addMember`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(memberResponse),
            }).then(result => {
                setElementsDisplays(['none', 'block'])
            }).catch(function(error) {
                console.log(error)
            });
        })
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Добавить информацию о члене команды
            </div>
            <AdminChangeMember
                role={role}
                lastName={lastName}
                firstName={firstName}
                patronymicName={patronymicName}
                email={email}
                workInformation={workInformation}
                educationInformation={educationInformation}
                rewardsInformation={rewardsInformation}
                setRole={setRole}
                setPhoto={setPhoto}
                setLastName={setLastName}
                setFirstName={setFirstName}
                setPatronymicName={setPatronymicName}
                setEmail={setEmail}
                setWorkInformation={setWorkInformation}
                setEducationInformation={setEducationInformation}
                setRewardsInformation={setRewardsInformation}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
            />
            <SuccessMessage 
                    title={'Информация о новом члене команды'} 
                    savedTitle={'сохранена'} 
                    departmentTitle={'Команда'} 
                    changedTitle={'информации о составе команде'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAddMember;