
import {Col} from "react-bootstrap";
import './main.scss'

export const AdvantageElement = ({item}) => {

	return (
        <Col xl={4} lg={4} md={4} sm={6} xs={12} className="advantage">
            <div className="advantage--wrapper">
                <div className="advantage__image--wrap">
                    <img className="advantage__image" src={item?.src} alt="tea"/>
                </div>
                <div className="advantage__name">
                    {item?.title}
                </div>
                <div className="advantage__text">
                    <div className="advantage__text--description">
                        {item?.description}
                    </div>
                </div>
            </div>
        </Col>
	)
}

export default AdvantageElement