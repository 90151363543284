import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState} from 'react';
import {useSelector} from 'react-redux';

import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminChangeArticle from './';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAddArticle = () => {

    const authUser = useSelector(x => x.auth.user);

    const [articleTitle, setArticleTitle] = useState("")
    const [articleDescription, setArticleDescription] = useState("")
    const [articleUrl, setArticleUrl] = useState("")
    const [articleLogo, setArticleLogo] = useState(null);
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])

    //перевод файлов изображений в base64
    const handleArticleLogo = (objectToRead) => {
        return objectToRead.map((element) => {
            const reader = new FileReader();            
            let promise = new Promise((resolve, reject) => {
                reader.onloadend = function() {
                    resolve({
                        result: reader.result
                    })
                }
                reader.onerror = function (error) {
                    reject("error")
                };
            })
            reader.readAsDataURL(element.imageValue.item(0));
            return promise;
        })
    }

    const acceptChanges = () => {

        let promisedLogo = handleArticleLogo(articleLogo);
        Promise.all(promisedLogo).then(results => {
            const articleResponse = {
                title: articleTitle, 
                description: articleDescription, 
                url: articleUrl, 
                logo: results[0].result, 
                author: Number(authUser.id)
            }
        
            fetch(`${process.env.REACT_APP_API_URL}/addArticle`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(articleResponse),
            }).then(result => {
                setElementsDisplays(['none', 'block'])
            }).catch(function(error) {
                console.log(error)
            });
        })
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Добавить материал
            </div>
            <AdminChangeArticle
                articleTitle={articleTitle}
                articleUrl={articleUrl}
                articleDescription={articleDescription}
                setArticleTitle={setArticleTitle}
                setArticleUrl={setArticleUrl}
                setArticleDescription={setArticleDescription}
                setArticleLogo={setArticleLogo}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
            />
            <SuccessMessage 
                    title={'Новый материал'} 
                    savedTitle={'сохранен'} 
                    departmentTitle={'Полезные материалы'} 
                    changedTitle={'материалов'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAddArticle;