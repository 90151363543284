import * as React from 'react'
import {Route, Routes, Navigate, useNavigate, useLocation} from 'react-router-dom';
import {history } from './_helpers/history';
import Login from './_login/Login';

import MainDashboardLayout from './Layouts/MainDashboard/MainDashboardLayout';
import MainPage from './pages/MainPage';
import AllForms from './pages/SeconLevelPages/AllForms';
import Form from './pages/Form';
import AboutProject from './pages/SeconLevelPages/AboutProject/AboutProject';
import Persons from './pages/SeconLevelPages/Persons/Persons';
import Person from './pages/SeconLevelPages/Person/Person';
import PublicationSections from './pages/SeconLevelPages/PublicationSections/PublicationSections';
import Publication from './pages/SeconLevelPages/Publication/Publication';

import AdminDashboardLayout from './Layouts/AdminDashboard/AdminDashboardLayout';
import AdminPage from './pages/Admin/AdminPage';
import AdminPagesTexts from './pages/Admin/AdminPagesTexts';
import AdminAddForm from './pages/Admin/AdminAdd/AdminAddForm';
import AdminForm from './pages/Admin/AdminForm';
import AdminMenu from './pages/Admin/AdminMenu';
import AdminUsers from './pages/Admin/AdminUsers';
import AdminContacts from './pages/Admin/AdminContacts';
import AdminArticles from './pages/Admin/AdminArticles';
import AdminMembers from './pages/Admin/AdminMembers';
import AdminPublications from './pages/Admin/AdminPublications';
import AdminPublicationSections from './pages/Admin/AdminPublicationSections';

import AdminAlterText from './pages/Admin/AdminPagesTexts/alter';
import AdminAlterUser from './pages/Admin/AdminAdd/AdminAddUser/alter';
import AdminAlterForm from './pages/Admin/AdminForm/alterForm';
import AdminAlterPhone from './pages/Admin/AdminContacts/alterPhone';
import AdminAlterEmail from './pages/Admin/AdminContacts/alterEmail';
import AdminAlterMenuPoint from './pages/Admin/AdminAdd/AdminAddMenuPoint/alter';
import AdminAlterMember from './pages/Admin/AdminAdd/AdminAddMember/alter';

import AdminAddPublication from './pages/Admin/AdminAdd/AdminAddPublication/add';
import AdminAddPublicationSection from './pages/Admin/AdminAdd/AdminAddPublicationSection/add';
import AdminAddUser from './pages/Admin/AdminAdd/AdminAddUser/add';
import AdminAddMember from './pages/Admin/AdminAdd/AdminAddMember/add';
import AdminAlterPublication from './pages/Admin/AdminAdd/AdminAddPublication/alter';
import AdminAlterPublicationSection from './pages/Admin/AdminAdd/AdminAddPublicationSection/alter';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/pages/Admin/AdminPage.scss';
import AdminAlterArticle from './pages/Admin/AdminAdd/AdminAddArticle/alter';
import AdminAddArticle from './pages/Admin/AdminAdd/AdminAddArticle/add';

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <Routes>
        <Route exact path="/" element={<MainDashboardLayout/>}>
          <Route index element={<MainPage/>}/>
          <Route exact path="forms" element={<AllForms/>}/>
          <Route exact path="forms/:currentId" element={<Form/>}/>
          <Route exact path="about" element={<AboutProject/>}/>
          <Route exact path="publicationSections" element={<PublicationSections/>}/>
          <Route exact path="publicationSections/:publicationSectionId" element={<Publication/>}/>
          <Route exact path="persons" element={<Persons/>}/>
          <Route exact path="persons/:personId" element={<Person/>}/>
        </Route> 
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/admin" element = {<AdminDashboardLayout/>}>
            <Route index element={<AdminPage/>}/>
            <Route exact path="menu" element={<AdminMenu/>}/>
            <Route exact path="menu/:pointId" element={<AdminAlterMenuPoint/>}/>
            <Route exact path="pagesTexts" element={<AdminPagesTexts/>}/>
            <Route exact path="pagesTexts/:textId" element={<AdminAlterText/>}/>
            <Route exact path="contacts" element={<AdminContacts/>}/>
            <Route exact path="contacts/email/:emailId" element={<AdminAlterEmail/>}/>
            <Route exact path="contacts/phone/:phoneId" element={<AdminAlterPhone/>}/>
            <Route exact path="forms" element={<AdminForm/>}/>
            <Route exact path="forms/newForm" element={<AdminAddForm/>}/>
            <Route exact path="forms/alter/:formId" element={<AdminAlterForm/>}/>
            <Route exact path="articles" element={<AdminArticles/>}/>
            <Route exact path="articles/newArticle" element={<AdminAddArticle/>}/>
            <Route exact path="articles/:articleId" element={<AdminAlterArticle/>}/>
            <Route exact path="members" element={<AdminMembers/>}/>
            <Route exact path="members/newMember" element={<AdminAddMember/>}/>
            <Route exact path="members/:memberId" element={<AdminAlterMember/>}/>
            {/* <Route exact path="members/:memberId" element={<AdminAlterMember/>}/> */}
            <Route exact path="publicationSections" element={<AdminPublicationSections/>}/>
            <Route exact path="publicationSections/newPublicationSection" element={<AdminAddPublicationSection/>}/>
            <Route exact path="publicationSections/:publicationSectionId" element={<AdminAlterPublicationSection/>}/>
            <Route exact path="publicationSections/all/:publicationSectionId" element={<AdminPublications/>}/>
            <Route exact path="publicationSections/newPublication" element={<AdminAddPublication/>}/>
            <Route exact path="publicationSections/change/:publicationId" element={<AdminAlterPublication/>}/>
            <Route exact path="users/newuser" element={<AdminAddUser/>}/>
            <Route exact path="users/:userId" element={<AdminAlterUser/>}/>
            <Route exact path="users" element={<AdminUsers/>}/>
        </Route>
        <Route path="*" element={<Navigate to="/"/>}/>
    </Routes>
  );
}

export default App;
