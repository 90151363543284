import * as React from 'react'
import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminMenuPoint = ({pointTitle, pointUrl, setPointTitle, setPointUrl, acceptChanges, elementStyle}) => {

    const changeFields = (e) => {
        const itemId = e.target.id
        const itemValue = e.target.value;

        if(Number(itemId) === 0)
            {setPointTitle(itemValue)}
        else if(Number(itemId) === 1)
            {setPointUrl(itemValue)}
    }

    return (
        <div className="admin__add" style={{display: elementStyle}}>
            <div className="admin__add__title">
                Данные пункта
            </div>
            <div className="admin__add__field-block">
                <div className="admin__add__field-title">
                    Название
                </div>
                <input id={0} className="admin__add__field admin__add__field__title" value={pointTitle} type='text' placeholder="..." onChange={(e) => {changeFields(e)}}/>
                <div className="admin__add__field-title">
                    Ссылка
                </div>
                <input id={1} className="admin__add__field admin__add__field__url" value={pointUrl} type='text' placeholder="..." onChange={(e) => {changeFields(e)}}/>
            </div>
            <div className="admin__add__button">
                <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default AdminMenuPoint;