
import * as React from 'react'

import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminChangePublicationSection from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAlterPublicationSection = () => {

    const authUser = useSelector(x => x.auth.user);
    let {publicationSectionId} = useParams();

    const [sectionTitle, setSectionTitle] = useState("")
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getPublicationSectionById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(publicationSectionId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setSectionTitle(data.title)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const acceptChanges = () => {
        const publicationSectionResponse = {
            id: Number(publicationSectionId),
            publicationSectionData: {
                title: sectionTitle, 
                author: Number(authUser.id)
            }}

        fetch(`${process.env.REACT_APP_API_URL}/alterPublicationSection`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(publicationSectionResponse),
        }).then(result => {
            setElementsDisplays(['none', 'block'])
        }).catch(function(error) {
            console.log(error)
        });
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Изменить секцию публикаций
            </div>
            <AdminChangePublicationSection
                sectionTitle={sectionTitle}
                setSectionTitle={setSectionTitle}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
            />
           <SuccessMessage 
                    title={`Секция публикаций "${sectionTitle}"`} 
                    savedTitle={'изменена'} 
                    departmentTitle={'Публикации'} 
                    changedTitle={'публикаций'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAlterPublicationSection;