
import * as React from 'react'

import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminChangePublication from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAlterPublication = () => {

    let {publicationId} = useParams();
    const authUser = useSelector(x => x.auth.user);

    const [publicationTitle, setPublicationTitle] = useState("")
    const [publicationUrl, setPublicationUrl] = useState("")
    const [publicationSection, setPublicationSection] = useState(1)
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])

    const [publicationSections, setPublicationSections] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getPublicationById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(publicationId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);

                setPublicationTitle(data.content)
                setPublicationUrl(data.linkAddress)
                setPublicationSection(Number(data.section))
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        }).then(
            fetch(`${process.env.REACT_APP_API_URL}/getAllActivePublicationSections`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({}),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    const publicationSectionsData = data.map((section) => ({
                        id: section.id, 
                        title: section.title,
                    }))
                    setPublicationSections(publicationSectionsData);
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        )
    }, [setAppState])


    const acceptChanges = () => {
        const publicationResponse = {
            id: Number(publicationId), 
            publicationData: {
                title: publicationTitle, 
                url: publicationUrl, 
                section: Number(publicationSection), 
                author: Number(authUser.id)}
            }

        fetch(`${process.env.REACT_APP_API_URL}/alterPublication`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(publicationResponse),
        }).then(result => {
            setElementsDisplays(['none', 'block'])
        }).catch(function(error) {
            console.log(error)
        });
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Изменить публикацию
            </div>
            <AdminChangePublication
                publicationTitle={publicationTitle}
                publicationUrl={publicationUrl}
                publicationSection={publicationSection}
                setPublicationSection={setPublicationSection}
                setPublicationTitle={setPublicationTitle}
                setPublicationUrl={setPublicationUrl}
                acceptChanges={acceptChanges}
                elementStyle={elementsDisplays[0]}
                publicationSections={publicationSections}
            />
            <SuccessMessage 
                    title={`Измененная публикация`} 
                    savedTitle={'сохранена'} 
                    departmentTitle={'Публикации'} 
                    changedTitle={'публикаций'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAlterPublication;