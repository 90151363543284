import * as React from 'react'

import {Container} from 'react-bootstrap';

const AdminPage = () => {

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Главная страница
            </div>
            <div className='admin__mainpage'>
                <div className='admin__mainpage__title'>
                    Информация для администраторов
                </div>
                <div className='admin__mainpage__text'>
                    <div className='admin__mainpage__text--title'>
                        Обозначение начала абзаца
                    </div>
                    <div className='admin__mainpage__text--main'>
                        При добавлении текстового контента для обозначения перехода на новую строку используйте элемент 
                        <span style={{fontWeight: 600}}> &#8249;br/&#8250;</span>
                    </div>
                    <div className='admin__mainpage__text__example--title'>
                        Пример 
                    </div>
                    <div className='admin__mainpage__text__example--main'>
                        В окно ввода введен текст: 
                        <span style={{fontStyle: "italic", color: "#E41D37"}}> Дайте посмотреть &#8249;br/&#8250; Держите</span>
                        <br/>
                        После сохранения текст отобразится так: 
                        <br/>
                        <span style={{fontStyle: "italic", color: "#E41D37"}}>Дайте посмотреть <br/> Держите </span>
                    </div>
                </div>
                <div className='admin__mainpage__text'>
                    <div className='admin__mainpage__text--title'>
                        Выделение цветом
                    </div>
                    <div className='admin__mainpage__text--main'>
                        При добавлении текстового контента для выделения слова или фразы красным цветом используйте элемент 
                        <span style={{fontWeight: 600}}> &#8249;span style="color: #E41D37"&#8250; &#8249;span/&#8250;
                        </span>
                    </div>
                    <div className='admin__mainpage__text__example--title'>
                        Пример 
                    </div>
                    <div className='admin__mainpage__text__example--main'>
                        В окно ввода введен текст: 
                        <br/>
                        <span style={{fontStyle: "italic"}}> Это &#8249;span style="color: #E41D37"&#8250; красное &#8249;span/&#8250; яблоко
                        </span>
                        <br/>
                        После сохранения текст отобразится так: 
                        <br/>
                        <span style={{fontStyle: "italic"}}> Это <span style={{color: "#E41D37"}}>красное </span> яблоко </span>
                    </div>
                    <div className='admin__mainpage__text--note'>
                        Для изменения цвета выделения текста вставьте на место E41D37 код RGB нужного цвета 
                        <br/>
                        Подобрать цвет и определить его RGB код можно например на сайте <a href={"https://colorscheme.ru/"} target={"blank"}>ColorScheme.ru</a>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default AdminPage;