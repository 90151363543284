import * as React from 'react'

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useLayoutEffect, useState} from "react";

import ChangeButtons from '../../../components/UI/ChangeButtons';
import {removeItemFunction} from "../AdminWrapper/RemoveItem";
import {cancelRemovingFunction} from "../AdminWrapper/CancelRemoving";

const AdminPublicationSections = () => {

    const authUser = useSelector(x => x.auth.user);

    const [publicationSections, setPublicationSections] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });
    
    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllPublicationSections`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const publicationSectionsData = data.map((section) => ({
                    id: section.id, 
                    title: section.title,
                    sectionKind: section.isActive === true ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                setPublicationSections(publicationSectionsData);
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const removePublicationSection = (currentId) => {
        const alterPublicationSection = publicationSections.map(section => section.id !== currentId ? section : {
            id: section.id, 
            title: section.title,
            sectionKind: ['none', 'block', 'block']
        })
        removeItemFunction(currentId, 'removePublicationSection', authUser)
        setPublicationSections(alterPublicationSection)
    }

    const cancelRemovingPublicationSection = (currentId) => {
        const alterPublicationSection = publicationSections.map(section => section.id !== currentId ? section : {
            id: section.id, 
            title: section.title,
            sectionKind: ['block', 'block', 'none']
        })
        cancelRemovingFunction(currentId, 'cancelRemovingPublicationSection', authUser)
        setPublicationSections(alterPublicationSection)
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Секции публикаций
            </div>
            <div className="admin__btn-group"> 
                <Link to={'/admin/publicationSections/newPublicationSection'} className='admin__button'>Добавить секцию</Link>
                <Link to={`/admin/publicationSections/newPublication`} className='admin__button'>Добавить публикацию</Link>
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th>Название секции</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {publicationSections?.map((publicationSection, index) => {
                    return <tr key={index}>
                                <td>{index+1}</td>
                                <td>
                                    <Link to={`/admin/publicationSections/all/${publicationSection.id}`} 
                                        className='admin__item--link'>
                                        {publicationSection.title}
                                    </Link>
                                </td>
                                <td style={{textAlign: 'right'}}>
                                    <ChangeButtons 
                                        artificialId={index === publicationSection.id ? (index+1)*100 : index}
                                        itemId={publicationSection.id} 
                                        removeFunction={removePublicationSection} 
                                        cancelFunction={cancelRemovingPublicationSection} 
                                        linkAlter={String(publicationSection.id)}
                                        itemStatus={publicationSection.sectionKind}/>
                                </td>
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminPublicationSections;