import * as React from 'react';

import {Container} from 'react-bootstrap';
import {useLayoutEffect, useState} from "react";
import {useSelector} from 'react-redux';

import { removeItemFunction } from '../AdminWrapper/RemoveItem';
import { cancelRemovingFunction } from '../AdminWrapper/CancelRemoving';
import ChangeButtons from "../../../components/UI/ChangeButtons";

const AdminContacts = () => {

    const authUser = useSelector(x => x.auth.user);

    const [phoneContent, setPhoneContent] = useState([]);
    const [emailContent, setEmailContent] = useState([]);
    
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllEmails`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(''),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedEmails = data.map((item) => ({
                    id: item.id,
                    content: item.email,
                    title: 'E-mail',
                    emailKind: item.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                setEmailContent(changedEmails)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
         }).then(
            fetch(`${process.env.REACT_APP_API_URL}/getAllPhones`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(""),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    const changedPhones = data.map((item) => ({
                        id: item.id,
                        content: item.phone,
                        title: 'Телефон',
                        phoneKind: item.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                    }))
                    setPhoneContent(changedPhones)
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        )
    }, [setAppState])

    const removeEmail = (currentId) => {
        const alterEmail = emailContent.map(item => item.id !== currentId ? item : {
            id: item.id, 
            title: item.title, 
            content: item.content, 
            emailKind: ['none', 'block', 'block']
        })
        setEmailContent(alterEmail)
        removeItemFunction(currentId, 'deleteEmail', authUser)
    }

    const cancelRemovingEmail = (currentId) => {
        const alterEmail = emailContent.map(item => item.id !== currentId ? item : {
            id: item.id, 
            title: item.title, 
            content: item.content, 
            emailKind: ['block', 'block', 'none']
        })
        setEmailContent(alterEmail)
        cancelRemovingFunction(currentId, 'cancelDeletingEmail', authUser)
    }

    const removePhone = (currentId) => {
        const alterPhone = phoneContent.map(item => item.id !== currentId ? item : {
            id: item.id, 
            title: item.title, 
            content: item.content, 
            phoneKind: ['none', 'block', 'block']
        })
        setPhoneContent(alterPhone)
        removeItemFunction(currentId, 'deletePhone', authUser)
     }
 
     const cancelRemovingPhone = (currentId) => {
        const alterPhone = phoneContent.map(item => item.id !== currentId ? item : {
            id: item.id, 
            title: item.title, 
            content: item.content, 
            phoneKind: ['block', 'block', 'none']
        })
        setPhoneContent(alterPhone)
        cancelRemovingFunction(currentId, 'cancelDeletingPhone', authUser)
     }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Контакты сайта
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th>Заголовок</th>
                        <th>Контент</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {emailContent && emailContent?.map((item, i) => {
                    return <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item.title}</td>
                                <td>{item.content}</td>
                                <td>
                                    <ChangeButtons 
                                    itemId={item.id} 
                                    artificialId={i === item.id ? (i+1)*100 : i}
                                    removeFunction={removeEmail} 
                                    cancelFunction={cancelRemovingEmail} 
                                    linkAlter={'email/'+String(item.id)}
                                    itemStatus={item.emailKind}/>
                                </td>
                            </tr>
                })}
                {phoneContent && phoneContent?.map((item, i) => {
                    return <tr key={i}>
                                <td>{i+1+emailContent.length}</td>
                                <td>{item.title}</td>
                                <td>{item.content}</td>
                                <td>
                                    <ChangeButtons 
                                    itemId={item.id} 
                                    artificialId={i === item.id ? (i+emailContent.length+1)*100 : i+emailContent.length}
                                    removeFunction={removePhone} 
                                    cancelFunction={cancelRemovingPhone} 
                                    linkAlter={'phone/'+String(item.id)}
                                    itemStatus={item.phoneKind}/>
                                </td>
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminContacts;
