
import {useState} from "react";

import AnswerOptions from "../AnswerOptions/index";

import './AnswerTable.scss';
import './media.scss';

export const AnswerTable = ({answerType, inputType, strings, columns, returnArray}) => {

    const [isRadio, setIsRadio] = useState({id: -1}); //корректное отображение вопросов типа oneAnswer
    const [isCheckbox, setIsCheckbox] = useState(strings.map((element) => ({id: element.id, content: false}))); //корректное отображение вопросов типа manyAnswers
    const [tableAnswersArray, setTableAnswersArray] = useState(strings.map((element) => ({id: element.id, content: null}))); //корректная работа с таблицей типа radio
    const [answersArray, setAnswersArray] = useState(null); //массив со всеми выбранными или введенными вариантами ответа

    //обрабатывает отображение выборов пользователя и помещает новый выбор в массив ответа answersArray
    const handleChangeInput = (currentStringId, currentColumnId, content) => { 
        let result = content;
        switch(answerType) {
            case 0:
                setIsRadio({id: currentStringId, content: true})
                result=currentStringId;
                break;
            case 1:
                const updatedCheckedState = isCheckbox?.map((element) => (element.id === currentStringId ? {id: element.id, content: !element.content} : element));
                setIsCheckbox(updatedCheckedState);
                result=updatedCheckedState;
                break;
            case 4: 
                const updatedTableAnswer = tableAnswersArray?.map((element) => 
                (element.id === currentStringId ? {id: currentStringId,  
                    content: {
                        string: currentStringId, 
                        column: currentColumnId}} : element));
                setTableAnswersArray(updatedTableAnswer);
                result = updatedTableAnswer;
                break;
            case 5: 
                const updatedTableAnswerCheckbox = tableAnswersArray.map((element) => (element.id === currentStringId ? {id: element.id, content: content} : element));
                setTableAnswersArray(updatedTableAnswerCheckbox);
                result = updatedTableAnswerCheckbox;
                break;
            default:
                break;
        }
        setAnswersArray({answerType: answerType, content: result});
        returnArray({answerType: answerType, content: result})
    };
 
	return (
        <div className="answer__table__wrapper">
            <table className={`answer__table answer__table__style__${answerType}`}>
                <thead>
                    <tr>
                        <th></th>
                        {columns?.map((column, i) => ( <th key={i}>{column.content}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {strings?.map((string, i) => ( 
                        <tr key={i}>
                            <th>{string.content}</th>
                            <AnswerOptions
                                optionId={string.id}
                                answerType={answerType} 
                                inputType={inputType} 
                                arrayCount={columns} 
                                onChange={handleChangeInput}
                                checkRadio={isRadio}
                                checkCheckbox={isCheckbox}
                            />
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
	)
}

export default AnswerTable;