
const removeDangerousOutline = () => {

    const arrayOfAllQuestions = document.getElementsByClassName('answer-block');
    const arrayLength = arrayOfAllQuestions.length;
    let i = 0;

     while(i < arrayLength) {
        arrayOfAllQuestions[i].classList.remove('answer-block__dangerous');
        i++;
     }
}
    
    export default removeDangerousOutline;