import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useLayoutEffect, useState} from "react";
import {useParams} from 'react-router-dom';

import ChangeButtons from '../../../components/UI/ChangeButtons';
import {removeItemFunction} from "../AdminWrapper/RemoveItem";
import {cancelRemovingFunction} from "../AdminWrapper/CancelRemoving";

const AdminPublications = () => {

    const authUser = useSelector(x => x.auth.user);
    let {publicationSectionId} = useParams();
    
    const [publications, setPublications] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });
    
    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllPublicationsForSection`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(publicationSectionId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const publicationsData = data.map((publication) => ({
                    id: publication.id, 
                    title: publication.content,
                    link: publication.linkAddress,
                    publicationKind: publication.isActive === true ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                setPublications(publicationsData);
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const removePublication = (currentId) => {
        const alterPublication = publications.map(publication => publication.id !== currentId ? publication : {
            id: publication.id, 
            title: publication.title,
            link: publication.link,
            publicationKind: ['none', 'block', 'block']
        })
        setPublications(alterPublication)
        removeItemFunction(currentId, 'removePublication', authUser)
    }

    const cancelRemovingPublication = (currentId) => {
        const alterPublication = publications.map(publication => publication.id !== currentId ? publication : {
            id: publication.id, 
            title: publication.title,
            link: publication.link,
            publicationKind: ['block', 'block', 'none']
        })
        setPublications(alterPublication)
        cancelRemovingFunction(currentId, 'cancelRemovingPublication', authUser)
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                {"Публикации секции " + localStorage.getItem('choosenPublicationSection')}
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th style={{width: 50+"%"}}>Контент публикации</th>
                        <th>URL публикации</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {publications?.map((publication, index) => {
                    return <tr key={index}>
                                <td>{index+1}</td>
                                <td style={{textAlign: "justify"}}>{publication.title}</td>
                                <td>{publication.link}</td>
                                <td style={{textAlign: 'right'}}>
                                    <ChangeButtons 
                                        artificialId={index === publication.id ? (index+1)*100 : index}
                                        itemId={publication.id} 
                                        removeFunction={removePublication} 
                                        cancelFunction={cancelRemovingPublication} 
                                        linkAlter={`/admin/publicationSections/change/${publication.id}`}
                                        itemStatus={publication.publicationKind}/>
                                </td>
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminPublications;