import * as React from 'react'

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useLayoutEffect, useState} from "react";
import {useSelector} from 'react-redux';

import {removeItemFunction } from "../AdminWrapper/RemoveItem";
import {cancelRemovingFunction } from "../AdminWrapper/CancelRemoving";
import splitTime from '../../../components/UI/Functions/splitTime';
import ChangeButtons from '../../../components/UI/ChangeButtons';

const AdminForm = () => {

    const [forms, setForms] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
      });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getForms`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(''),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const allForms = data.map((dataString) => ({
                    id: dataString.id,
                    url: 'https://zdorov.tpu.ru/forms/'+ dataString.formUrl,
                    time: splitTime(dataString.creationTime), 
                    title: dataString.formTitle,
                    status: dataString.isActive ? "опубликована" : "черновик",
                    formKind: dataString.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                setForms(allForms)
            })
            setAppState({ loading: false, repos: response});
        })
    }, [setAppState])

    const getReport = (e) => {
        const formId = e.currentTarget.id
        fetch(`${process.env.REACT_APP_API_URL}/report/${formId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formId),
        }).then(response => {
            if ((response.ok === true) & (response.status === 200)) {
                return response.blob();
            }
        })
        .then(data => {
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Отчет.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    const authUser = useSelector(x => x.auth.user);

    const removeForm = (currentId) => {
        const alterForms = forms.map(formItem => formItem.id !== currentId ? formItem : {
            id: formItem.id,
            url: formItem.url,
            time: formItem.time, 
            title: formItem.title,
            status: "черновик",
            formKind: ['none', 'block', 'block']
        })
        setForms(alterForms)
        removeItemFunction(currentId, 'removingForm', authUser)
    }
    
    const cancelRemovingForm = (currentId) => {
        const alterForms = forms.map(formItem => formItem.id !== currentId ? formItem : {
            id: formItem.id,
            url: formItem.url,
            time: formItem.time, 
            title: formItem.title,
            status: "опубликована",
            formKind: ['block', 'block', 'none']
        })
        setForms(alterForms)
        cancelRemovingFunction(currentId, 'restoreForm', authUser)
    }


    return (
        <Container fluid className='admin-decorator'>
        <div className='admin-decorator__title'>
            Анкеты
        </div>
        <div className="admin__btn-group"> 
                <Link to={'/admin/forms/newForm'} className='admin__button'>Добавить анкету</Link>
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 5+"%"}}>ID</th>
                        <th>Название анкеты</th>
                        <th>URL анкеты</th>
                        <th>Дата создания</th>
                        <th>Выгрузить данные</th>
                        <th style={{width: 11+"%"}}>Статус</th>
                        <th style={{width: 12+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {forms && forms?.map((item, i) => { 
                    return <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.title}</td>
                            <td>{item.url}</td>
                            <td>{item.time}</td>
                            <td><button id={item.id} className='admin__button__get' onClick={(e) => getReport(e)}>Выгрузка</button></td>
                            <td>{item.status}</td>
                            <td style={{textAlign: 'right'}}>
                                <ChangeButtons 
                                    itemId={item.id} 
                                    artificialId={i === item.id ? (i+1)*100 : i}
                                    removeFunction={removeForm} 
                                    cancelFunction={cancelRemovingForm} 
                                    linkAlter={'alter/'+String(item.id)}
                                    itemStatus={item.formKind}/>
                            </td>
                        </tr>
                })}
                </tbody>
            </table>
    </Container>
    )
}

export default  AdminForm;