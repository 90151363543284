import * as React from 'react'

import DownloadImages from '../../../../components/UI/Question/DownloadImages';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminChangeArticle = ({articleTitle, articleUrl, articleDescription,
                            setArticleTitle, setArticleUrl, setArticleLogo, setArticleDescription,
                            elementStyle, acceptChanges, prevArticleLogo}) => {


    const changeFields = (e) => {
        const currentValue = e.target.value;
        const currentId = e.target.id;

        if(Number(currentId) === 0) {
            setArticleTitle(currentValue)
        } else if (Number(currentId) === 1) {
            setArticleDescription(currentValue)
        } else if (Number(currentId) === 2) {
            setArticleUrl(currentValue)
        }
    }

    return (
            <div style={{display: elementStyle}}>
                <DownloadImages marginStyle={{}} resultStatus="(обязательно)" title={'Логотип материала'} oneOrMany={1} getImages={setArticleLogo} data={prevArticleLogo}/>
                <div className='admin__article__input-title'>
                    Название материала
                </div>
                <input
                    id={0}
                    className='admin__article__input'
                    value={articleTitle} 
                    type='text' 
                    placeholder="..."  
                    onChange={(e) => {changeFields(e)}}/>
                <div className='admin__article__input-title'>
                    Краткое описание
                </div>
                <input
                    id={1}
                    className='admin__article__input'
                    value={articleDescription} 
                    type='text' 
                    placeholder="..."  
                    onChange={(e) => {changeFields(e)}}/>
                <div className='admin__article__input-title'>
                    URL
                </div>
                <textarea
                    id={2}
                    className='admin__article__textarea'
                    value={articleUrl} 
                    type='text' 
                    placeholder="..."  
                    onChange={(e) => {changeFields(e)}}/>
                <div className="admin__add__button">
                    <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                        Сохранить
                    </button>
                </div>
            </div>
    )
}

export default AdminChangeArticle;