import * as React from 'react';
import {useState, useLayoutEffect} from "react";
import {useParams} from 'react-router-dom';
import { Container} from 'react-bootstrap';
import '../SecondLevel.scss';

const Person = () => {

    let {personId} = useParams();

    const [person, setPerson] = useState({})

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getMemberById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(personId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const personData = {
                    id: data.id, 
                    lastName: data.lastName, 
                    firstName: data.firstName, 
                    patronymicName: data.patronymicName, 
                    workInformation: data.workInfo, 
                    email: data.email, 
                    role: data.role,
                    photo: data.image,
                    educationInformation: {__html: data.educationInfo},
                    rewardsInformation: {__html: data.rewardsInfo},
                }
                setPerson(personData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    
    return (
        <div className='second-level'>
            <Container fluid className='second-level__content'>
                <Container className='second-level__title'>
                    <div className='second-level__title--text'>
                        {JSON.parse(localStorage.getItem('choosenPersonality'))}
                    </div> 
                </Container>
                <Container className='second-level__personality'>
                    <div className="second-level__personality__info">
                        <img className="second-level__personality__info--photo" src={person?.photo} alt="person"/>
                        <div className="second-level__personality__info--personal">
                            <div className="second-level__personality__info--fio">
                                {person?.lastName + " " + person?.firstName + " " + person?.patronymicName}
                            </div>
                            <div className="second-level__personality__info--status">
                                {person?.workInformation}
                            </div>
                            <div className="second-level__personality__info--email">
                                {person?.email}
                            </div>
                        </div>
                    </div>
                    <div className="second-level__personality__title">
                        Образование и повышение квалификации
                    </div>
                    <p className="second-level__personality__education" dangerouslySetInnerHTML={person?.educationInformation}/>
                    <div className="second-level__personality__title">
                        Награды и поощрения
                    </div>
                    <p className="second-level__personality__education" dangerouslySetInnerHTML={person?.rewardsInformation}/>
                </Container>
            </Container>
        </div>
        
    )
}

export default Person;