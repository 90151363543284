
import {Container} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {useRef, useState, useLayoutEffect} from "react";

import AnswerSection from "../../components/UI/Answer/AnswerSection/index";
import checkAndPrepareSection from '../../components/UI/Answer/CheckFunctions/checkAndPrepareSection';
import addDangerousOutline from '../../components/UI/Answer/DangerousMessages/addDangerousOutline';
import LastPage from "../../components/UI/Answer/LastPage";
import checkAndPrepareLastPageData from '../../components/UI/Answer/CheckFunctions/checkAndprepareLastPageData';
import removeDangerousOutline from '../../components/UI/Answer/DangerousMessages/removeDangerousOutline';

import './Form.scss';
import './media.scss';


const Form = () => {

    let {currentId} = useParams();
    const [lastPageData, setLastPageData] = useState({choosesArray: [' '], dataArray: [' '], condition: false})

    const formRef = useRef(null); 
    const allFormRef = useRef(null);
    const inputTypes = ['radio','checkbox','text', 'text', 'radio', 'checkbox']
    const [buttonForthText, setButtonForthText] = useState("Сохранить ответы")
    const [blocksDisplay, setBlocksDisplay] = useState({buttonBlockDisplay: 'none', currentSectionDisplay: 'none', lastPageDisplay: 'none', successfulMessageDisplay: 'none', startBlockDisplay: 'flex'});
    const [buttonsDisplay, setButtonsDisplay] = useState({buttonBackDisplay: 'none', buttonForthDisplay: 'none', buttonSendDisplay: 'flex'}); //вид display кнопок
    const [sendAnswersDisplay, setSendAnswersDisplay] = useState('none');
    const [formId, setFormId] = useState(null)
    const [artificialFormId, setArtificialFormId] = useState(null)
    const [anketaInformation, setAnketaInformation] = useState([])
    const [partnersInformation, setPartnersInformation] = useState([])
    const [sectionInformation, setSectionInformation] = useState([{displayType: 'none'}])
    const [suggestionsInformation, setSuggestionsInformation] = useState([])

    const [sectionsAnswers, setSectionsAnswers] = useState([])

    const [pageNumber, setPageNumber] = useState(0)
    const [currentStep, setCurrentStep] = useState(0); //номер текущей активной секции

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    const questionOrder = (question1, question2) => {
        return question1.number < question2.number ? -1 : (question1.number >= question2.number ? 1 : 0);
    }

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getCurrentActiveForm`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(currentId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const formInformation = {
                    id: data.id,
                    title: data.formTitle, 
                    description: data.description, 
                }
                setFormId(data.id)
                setAnketaInformation(formInformation)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        }).then(
            fetch(`${process.env.REACT_APP_API_URL}/getCurrentActiveFormPartners`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(currentId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    setPartnersInformation(data)
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        ).then(
            fetch(`${process.env.REACT_APP_API_URL}/getCurrentActiveFormSuggestions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(currentId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    const lastPageSuggestions = data.map((element) => ({
                        id: element.id,
                        answerType: 1,
                        inputType: "checkBox",
                        title: element.title,
                    }))
                    setSuggestionsInformation(lastPageSuggestions)
                });
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            })
        )
    }, [setAppState])
    

    const startForm = () => {
        fetch(`${process.env.REACT_APP_API_URL}/createArtificialFormId`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formId),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setArtificialFormId(data)
            })
        }).then(
            fetch(`${process.env.REACT_APP_API_URL}/getFormContent`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formId),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    const allSections = data.map((section) => ({
                        title: section.title,
                        description: section.description,
                        id: section.id,
                        displayType: 'none',
                        questionList: section.content.map((question) => ({
                            id: question.id,
                            title: String(question.title),
                            answerType: question.questionKind-1,
                            inputType: inputTypes[question.questionKind-1],
                            hasDifferent: question.hasDifferent,
                            isRequired: question.isRequired,
                            option: {
                                strings: question.strings === undefined ? [''] : question.strings,
                                columns: question.columns === undefined ? [{id: -1}] : question.columns
                            },
                            number: question.number
                        })).sort(questionOrder)
                    }))
                    setSectionInformation(allSections)
                    setPageNumber(allSections.length)
                    setBlocksDisplay({buttonBlockDisplay: 'flex', 
                    currentSectionDisplay: 'none', 
                    lastPageDisplay: 'flex', 
                    successfulMessageDisplay: 'none', 
                    startBlockDisplay: 'none'})
                    });
            }).catch(function(error) {
                console.log(error)
            })
        )
    }
    // установка display для текущей секции
    sectionInformation[currentStep].displayType = blocksDisplay.currentSectionDisplay

    //обработка массива всех ответов формы
    const returnAllSectionArrays = (sectionId, arrayAnswers) => {
        const checkCondition = (sectionsAnswers.filter(element => element.id === sectionId)).length !== 0
        if(checkCondition) {
            const updatedSectionAnswers = sectionsAnswers.map((section) => (
                section.id === sectionId ?  {id: sectionId, content: arrayAnswers} : section
            ))
            setSectionsAnswers(updatedSectionAnswers)
        }
        else {
            setSectionsAnswers((current) => [...current, {id: sectionId, content: arrayAnswers}])
        }
    }

    const returnPersonData = (choosenCheckboxes=[], personalData=[], condition) => {
        setLastPageData({choosesArray: choosenCheckboxes, dataArray: personalData, condition: condition})
    }

    const sendData = () => {
        if(checkAndPrepareLastPageData(lastPageData, 2)) {
            const dangerousMessages = document.getElementsByClassName('last-page__block__dangerous-message');
            dangerousMessages[0].classList.add('last-page__block__dangerous-message--show');
        }
        else {
            const dangerousMessages = document.getElementsByClassName('last-page__block__dangerous-message');
            dangerousMessages[0].classList.remove('last-page__block__dangerous-message--show');
            setBlocksDisplay({buttonBlockDisplay: 'flex', 
                currentSectionDisplay: 'flex', 
                lastPageDisplay: 'none', 
                successfulMessageDisplay: 'none', 
                startBlockDisplay: 'none'})
            setButtonsDisplay({buttonBackDisplay: 'flex', 
                buttonForthDisplay: 'flex', 
                buttonSendDisplay: 'none'});
            const formDataObject = {
                artificialId: artificialFormId,
                personChooses: lastPageData.choosesArray, 
                personDataEmail: lastPageData.dataArray[0].content, 
                personDataPhone: lastPageData.dataArray[1].content, 
                personPermission: true};
            fetch(`${process.env.REACT_APP_API_URL}/getArtificialFormSuggestionsAndPermissions`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formDataObject),
                }).then( result => {
                    if(result.status !== 200) {
                        console.log(result)
                    }
                })
        }
    }

    //функция перехода к следующей секции
    const goForth = () => {
        setSendAnswersDisplay('none')
        removeDangerousOutline();
        const checkedObject = checkAndPrepareSection(sectionsAnswers[currentStep], sectionInformation[currentStep].questionList)
        if(checkedObject.hasQuestionsNeedAnswer) {
            setSendAnswersDisplay('flex')
            const firstDangerousElement = addDangerousOutline(checkedObject.arrayOfQuestionsNeedAnswer)
            firstDangerousElement.scrollIntoView({block: "center", inline: "center"});
        }
        else if (sectionsAnswers[currentStep] !== undefined){
            const responseObject = {artificialId: artificialFormId, sectionContent: sectionsAnswers[currentStep]};
            fetch(`${process.env.REACT_APP_API_URL}/sendArtificialFormSection`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(responseObject),
            }).then(response => {
                if(response.status === 200){
                    window.scrollTo(0, 70)
                    if(currentStep === pageNumber-2) {
                        setButtonForthText("Завершить анкетирование")
                        sectionInformation[currentStep].displayType = 'none';
                        setButtonsDisplay({buttonBackDisplay: 'flex', 
                            buttonForthDisplay: 'flex', 
                            buttonSendDisplay: 'none'});
                        setCurrentStep(prev => prev+1);
                    } else if (currentStep === pageNumber-1) {
                        window.scrollTo(0, allFormRef.current.offsetTop-70)
                        sectionInformation[currentStep].displayType = 'none';
                        setButtonsDisplay({buttonBackDisplay: 'none', 
                            buttonForthDisplay: 'none', 
                            buttonSendDisplay: 'none'});
                        setBlocksDisplay({buttonBlockDisplay: 'none', 
                            currentSectionDisplay: 'none', 
                            lastPageDisplay: 'none', 
                            successfulMessageDisplay: 'flex', 
                            startBlockDisplay: 'none'})
                    }
                    else {
                        setButtonForthText("Сохранить ответы")
                        sectionInformation[currentStep].displayType = 'none';
                        setButtonsDisplay({buttonBackDisplay: 'flex', 
                            buttonForthDisplay: 'flex', 
                            buttonSendDisplay: 'none'});
                        setCurrentStep(prev => prev+1);
                    }
                }
            })    
        }
        else {
            if(currentStep !== pageNumber-1) {
                sectionInformation[currentStep].displayType = 'none';
                setButtonsDisplay({buttonBackDisplay: 'flex', 
                    buttonForthDisplay: 'flex', 
                    buttonSendDisplay: 'none'});
                setCurrentStep(prev => prev+1);
            } else {
                setButtonForthText('Завершить анкетирование')
                sectionInformation[currentStep].displayType = 'none';
                setButtonsDisplay({buttonBackDisplay: 'flex', 
                    buttonForthDisplay: 'none', 
                    buttonSendDisplay: 'flex'});
                setBlocksDisplay({buttonBlockDisplay: 'flex', 
                    currentSectionDisplay: 'none', 
                    lastPageDisplay: 'flex', 
                    successfulMessageDisplay: 'none', 
                    startBlockDisplay: 'none'})
            }
        }
    };

    //функция перехода к предыдущей секции
    const goBack = () => {
        setSendAnswersDisplay('none')
        removeDangerousOutline();
        window.scrollTo(0, formRef.current.offsetTop-70)
        setButtonForthText("Сохранить ответы")

        if(currentStep === 0) {
            setBlocksDisplay({buttonBlockDisplay: 'flex', 
                currentSectionDisplay: 'none', 
                lastPageDisplay: 'flex', 
                successfulMessageDisplay: 'none', 
                startBlockDisplay: 'none'})
            setButtonsDisplay({buttonBackDisplay: 'none', buttonForthDisplay: 'none', buttonSendDisplay: 'flex'});
        }
        else {
            sectionInformation[currentStep].displayType = 'none';
            sectionInformation[currentStep-1].displayType = 'flex';
            setCurrentStep((prev) => prev-1)
        }
    };

    return (
        <Container fluid className='form' ref={allFormRef}>
            <div className="form__container">
                <div className="form__container__partners">
                    {partnersInformation && partnersInformation?.map((partner, i) => ( <img key={i} src={partner.partnerContent} className='partner-form' alt="partner"/>))}
                </div>
            </div>
            <div className='form__container'>
                <div className='form__container__title'>
                    {anketaInformation.title} 
                </div>
            </div>
            <div className='form__container'>
                <div className='form__container__description'>
                    {anketaInformation.description}
                </div>
            </div>
            <LastPage 
                questionList={suggestionsInformation} 
                style={{display: blocksDisplay.lastPageDisplay}} 
                returnPersonData={returnPersonData}/>
            <div className='form__content' ref={formRef}>
                <div className="form__container" style={{display: blocksDisplay.currentSectionDisplay}}>
                    <div className="form__container__page-number">
                        Страница {currentStep+1} из {pageNumber}
                    </div>
                </div>
                {sectionInformation?.map((section, i) => (
                    <AnswerSection key={i} 
                        section={section}
                        sectionQuestions={section.questionList}
                        returnAllSectionArrays={returnAllSectionArrays} 
                        style={{display: section.displayType}}/>
                ))}
                <div className='form__block' style={{display: sendAnswersDisplay}}>
                    <div className='form__block__dangerous-message'>
                        Некоторые вопросы выше остались без ответа. <br/> Пожалуйста, ответьте на выделенные красным вопросы и попробуйте отправить ответы снова
                    </div>
                </div>
            </div>
            <div className="form__container" style={{display: blocksDisplay.buttonBlockDisplay}}>
                <button className='form__listing' onClick={() => {goBack()}} style={{display: buttonsDisplay.buttonBackDisplay}}>
                   Назад
                </button>
                <button className='form__listing' onClick={() => {goForth()}} style={{display: buttonsDisplay.buttonForthDisplay}}>
                    {buttonForthText}
                </button>
                <button className='form__listing' onClick={() => {sendData()}} style={{display: buttonsDisplay.buttonSendDisplay}}>
                    Перейти к вопросам анкеты
                </button>
            </div>
            <div className="form__succesful-message" style={{display: blocksDisplay.successfulMessageDisplay}}>
                Ваши ответы успешно отправлены!
            </div>
            <div className="form__start-answering" style={{display: blocksDisplay.startBlockDisplay}}>
                <button className='form__listing' onClick={() => {startForm()}}>
                    Начать анкетирование
                </button>
            </div>
        </Container>
    )
}
export default Form;

