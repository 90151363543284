import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState} from "react";
import {useSelector} from 'react-redux';

import QuestionSection from "../../../../components/UI/Question/QuestionSection";
import LastPageAdmin from '../../../../components/UI/Question/LastPageAdmin';
import checkForm from './checkForm';
import DownloadImages from '../../../../components/UI/Question/DownloadImages';
import SuccessMessage from '../../../../components/UI/SuccessMessage';
import SaveButton from '../../../../components/UI/SaveButton';
import AddElementButton from '../../../../components/UI/AddElementButton';
import CheckBeforePublishing from './checkBeforePublishing';

const AdminAddForm = () => {

    //дисплеи и классы элементов
    const [elementsDisplay, setElementsDisplay] = useState({messageBlock: 'none', formBlock: 'block'})
    const [suggestionsDisplay, setSuggestionsDisplay] = useState('none');
    const [saveEverythingClassName, setSaveEverythingClassName] = useState('disabled')

    //тексты кнопок
    const [loading, setLoading] = useState('Сохранить'); //текст кнопки сохранения
    const [saveEverythingText, setSaveEverythingText] = useState('Опубликовать анкету')

    //доступность кнопок
    const [buttonSaveIsDisabled, setButtonSaveIsDisabled] = useState(true)
    const [addSuggestionsButton, setAddSuggestionsButton] = useState({isCurrentDisabled: true})
    const [addButtonStatus, setAddButtonStatus] = useState({isCurrentDisabled: true})

    //длины массивов и начальные цифровые значения
    const [imagesNumber, setImagesNumber] = useState({partners: 0, logos: 1})
    const [sectionListLength, setSectionListLength] = useState(0); //отображаемое общее количество секций
    const [sectionUpdatedNumber, setSectionUpdatedNumber] = useState(0); //разница между номером id и отображаемым номером
    const [currentId, setCurrentId] = useState(1); // текущее id секции

    //массив всех секций и массив контента секций
    const [sectionList, setSectionList] = useState([]); //массив всех отображаемых секций
    const [sectionContentList, setSectionContentList] = useState([]) //контент всех отображаемых секций

    //объявление пустых полей анкеты
    const [formTitle, setFormTitle] = useState(null); //название анкеты
    const [formDescription, setFormDescription] = useState(null); //описание анкеты
    const [formPartners, setFormPartners] = useState(null);
    const [formLogo, setFormLogo] = useState(null);
    const [formId, setFormId] = useState(null);

    const authUser = useSelector(x => x.auth.user);

    //изменение массива контента всех секций
    const constructSectionQuestions = (sectionObject) => { 
        const updateSection = sectionContentList.map((elem) => (
            elem.artificialId !== sectionObject.artificialId ? elem : sectionObject
        ))
        setSectionContentList(updateSection);
    }

    //удаление секции. секция удаляется из DOM-дерева и массива контента секций, из списка секций не удаляется
    const handleChangeRemoveSection = (removeId) => {
        const changedItemsAfterRemoving = sectionList.map(item => (item.id > removeId ? {id: item.id, currentNumber: (item.currentNumber-1)} : item))
        setSectionList(changedItemsAfterRemoving)
        const updateSectionContentList = sectionContentList.filter(item => item.artificialId !== Number(removeId))
        setSectionContentList(updateSectionContentList)
        setSectionUpdatedNumber(prev => prev+1)
        setSectionListLength(prev => prev-1)
        if(sectionListLength === 1 || removeId === sectionContentList[sectionContentList.length-1].artificialId) {
            setAddButtonStatus({isCurrentDisabled: false, currentDisplay: 'flex'})
        }
    }

    //добавление секции
    const handleChangeSectionNumber = () => {
        setAddButtonStatus({isCurrentDisabled: false, currentDisplay: 'none'})
        setSectionListLength(prev => prev+1)
        setCurrentId(prev => prev+1)
        setSectionList(current => [...current, {id: currentId, currentNumber: currentId-sectionUpdatedNumber}])
        setSectionContentList(current => [...current, {checkId: null, artificialId: currentId, contentIds: [{id: 0, checkId: null}]}])
    }

    //перевод файлов изображений в base64
    const handleFormImages = (kind, objectToRead) => {
        return objectToRead.map((element) => {
            const reader = new FileReader();            
            let promise = new Promise((resolve, reject) => {
                reader.onloadend = function() {
                    resolve({
                        kind: kind,
                        result: reader.result
                    })
                }
                reader.onerror = function (error) {
                    reject("error")
                };
            })
            reader.readAsDataURL(element.imageValue.item(0));
            return promise;
        })
    }

    //показать блок предложений для респондентов
    const handleChangeRespondentSuggestions = () => {
        setAddSuggestionsButton({isCurrentDisabled: false, currentDisplay: 'none'})
        setSuggestionsDisplay('flex')
    }

    //подготовить данные анкеты к сохранению
    const checkAndPrepare = () => {
        const objectNeedsChanges = checkForm(formTitle, formLogo) //проверка контента формы на ошибки
        if(objectNeedsChanges.hasObjectsNeedRemake) {
            setLoading("Заполните обязательные поля!")
        }
        else {
            setLoading("Сохранение...")
            let promisesPartners = handleFormImages('partner', formPartners);
            let promisesLogo = handleFormImages('logo', formLogo);
            Promise.all(promisesLogo.concat(promisesPartners)).then(results => {
                const formResponse = {
                    id: formId,
                    titleResponse: formTitle, 
                    descriptionResponse: formDescription,
                    formPartnersResponse: results.filter(e => e.kind === 'partner').map(e => e.result),
                    formAuthor: authUser.id,
                    formLogoResponse: results.filter(e => e.kind === 'logo').map(e => e.result)[0]
                }

                //формирование объекта формы
                fetch(`${process.env.REACT_APP_API_URL}/addNewForm`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formResponse),
                }).then((response) => {
                    if(response.status == 200) {
                        response.text().then(text => {
                            const data = text && JSON.parse(text);
                            setFormId(data)
                        });
                        setLoading("Сохранено")
                        setButtonSaveIsDisabled(false)
                        setSaveEverythingClassName('active')
                        setAddButtonStatus({isCurrentDisabled: false})
                        setAddSuggestionsButton({isCurrentDisabled: false})
                    }
                    else {
                        setLoading("Что-то пошло не так")
                    }
                })
            }).catch(error => {
                setLoading("Что-то пошло не так")
            });
        }
    }

    //подготовить анкету к публикации
    const saveEverything = () => {
        if(CheckBeforePublishing(sectionContentList)) {
            fetch(`${process.env.REACT_APP_API_URL}/publishForm`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({id: formId, changingAuthor: authUser.id}),
            }).then((response) => {
                if(response.status == 200) {
                    setSaveEverythingClassName('unvisible')
                    setElementsDisplay({messageBlock: 'block', formBlock: 'none'})
                }
                else {
                    setSaveEverythingText("Что-то пошло не так") 
                }
            })
        }
        else {
            setSaveEverythingText("Сохраните содержимое секций") 
        }
    }

    return (
        <Container fluid className='admin-decorator'>
            <div style={{display: elementsDisplay.formBlock}}>
                <div className='admin-decorator__title'>
                    Новая анкета
                </div>
                <div className='admin__form__input-title'>
                    Заголовок <span style={{color: '#E41D37'}}>(обязательно)</span>
                </div>
                <input className='admin__form__input' type='text' onChange={(e) => {setFormTitle(e.target.value)}}>

                </input>
                <div className='admin__form__input-title'>
                    Описание
                </div>
                <textarea className='admin__form__input' type='text' onChange={(e) => {setFormDescription(e.target.value)}}/>
                <DownloadImages marginStyle={{}} resultStatus="(обязательно)" title={'Логотип анкеты'} oneOrMany={imagesNumber.logos} getImages={setFormLogo}/>
                <DownloadImages marginStyle={{marginBottom: '0vh'}} title={'Загрузка логотипов партнеров'} getImages={setFormPartners}/>
                <SaveButton 
                    saveChanges={checkAndPrepare} 
                    responseText={loading}
                    currentClassName={'active'}
                    isCurrentDisabled={false}/>
                <AddElementButton 
                    addElement={handleChangeRespondentSuggestions} 
                    addElementText={'Добавить предложения для анкетируемых'} 
                    isCurrentDisabled={addSuggestionsButton.isCurrentDisabled}
                    currentDisplay={addSuggestionsButton.currentDisplay}/>
                <LastPageAdmin currentStyle={{display: suggestionsDisplay, marginTop: '20px'}} currentFormId={formId}/>
                <AddElementButton 
                    addElement={handleChangeSectionNumber} 
                    addElementText={'Добавить секцию'} 
                    isCurrentDisabled={addButtonStatus.isCurrentDisabled}
                    currentDisplay={addButtonStatus.currentDisplay}/>
                {sectionList && sectionList?.map((block, i) => (
                <QuestionSection key={i} 
                        currentFormId={formId}
                        sectionId={block.id} 
                        constructSectionQuestions={constructSectionQuestions} 
                        sectionCurrentNumber={block.currentNumber}
                        sectionNumber={sectionListLength} 
                        sectionVisible={block.display}
                        removeSection={handleChangeRemoveSection}
                        handleChangeSectionNumber={handleChangeSectionNumber}
                    />
                ))}
            </div>
            <SaveButton
                    saveChanges={() => {saveEverything()}} 
                    responseText={saveEverythingText}
                    currentClassName={saveEverythingClassName}
                    isCurrentDisabled={buttonSaveIsDisabled}/>
            <SuccessMessage
                        title={'Анкета'} 
                        savedTitle={'опубликована'} 
                        departmentTitle={'Анкеты'} 
                        changedTitle={'анкеты'} 
                        displayStyle={elementsDisplay.messageBlock}/>
        </Container>
    )
}

export default  AdminAddForm ;