
import {Col} from "react-bootstrap";
import {Link} from 'react-router-dom';

import './PersonCard.scss';

export const PersonCard = ({person, linkAlter='/notFound'}) => {

    const changeTitle = () => {
        localStorage.setItem('choosenPersonality', JSON.stringify(person?.role));
    }

	return (
        <Col xl={3} lg={4} md={4} sm={6} xs={12} className="person-card--wrapper">
            <Link to={linkAlter} className="person-card" onClick={() => changeTitle()}>
                <div className="person-card__top">
                    <div className="person-card__image--wrap">
                        <img className="person-card__image" src={person?.photo} alt="person"/>
                    </div>
                    <div className="person-card__name">
                        {person?.lastName + " " + person?.firstName + " " + person?.patronymicName}
                    </div>
                </div>
                <div className="person-card__text">
                    <div className="person-card__text--description">
                        {person?.role}
                    </div>
                </div>
            </Link>
        </Col>
	)
}

export default PersonCard;