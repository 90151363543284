//функция проверки контента формы на ошибки
const checkQuestions = (question) => {

    let result = false;
    //проверка на пустоту массива
    const checkLength = (checkedObject=[]) => {
        return (checkedObject.length !== 0) ? true : false
    }

    //проверка на пустоту содержания input
    const checkTitle = (titleObject) => {
        return (titleObject !== null) ? (titleObject.trim()!=='') ? true : false : false
    }

    //проверка на наличие пустых опций в вопросе
    const checkQuestionOptionsList = (questionObject, answerType) => {

        let result = false;

        if(answerType===2 || answerType===3) {
            result = false;
        }

        else if(answerType===4 || answerType===5) {
            if(checkLength(questionObject.optionsList.strings) && checkLength(questionObject.optionsList.columns)) {
                const stringsLength = checkLength(questionObject.optionsList.strings.filter((option => checkTitle(option.content)===false)))
                const columnsLength = checkLength(questionObject.optionsList.columns.filter((option => checkTitle(option.content)===false)))
                if(stringsLength===true || columnsLength===true)
                    result = true;
                else result = false;
            }
            else {result = true}
        }
        else if(answerType===0 || answerType===1){
            if(checkLength(questionObject.optionsList)) {
                result = checkLength(questionObject.optionsList.filter((option => checkTitle(option.content) === false)))
            }
            else {result = true}
        }
        return result;
    }

    const sectionHasQuestion = question !== null ? true : false
    
    if(sectionHasQuestion) {
        //заполнение массива вопросов, имеющих пустые опции
        if(checkQuestionOptionsList(question, question.answerType)===true || checkTitle(question.title)===false) {
            result = true;
        }
    }
    else {
        result = true;
    }
    return result;
} 

export default checkQuestions;