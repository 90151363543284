import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';

import checkUser from './checkNewUser';
import dangerMessage from './dangerMessage';
import removeDangerMessage from './removeDangerMessage';
import SuccessMessage from '../../../../components/UI/SuccessMessage';
import AdminUserPoint from '.';

import '../../AdminPage.scss'
import '../AdminAdd.scss';

const AdminAlterUser = () => {

    let { userId } = useParams();
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });
    const [currentRole, setCurrentRole] = useState(1);
    const [userCredentials, setUserCredentials] = useState([])
    const [elementsDisplays, setElementsDisplays] = useState(['flex', 'none'])

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getUserById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(userId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const userData = [
                    {credType: 0, credTitle: "Фамилия", content: data.lastName},
                    {credType: 1, credTitle: "Имя", content: data.firstName},
                    {credType: 2, credTitle: "Отчество", content: data.patronymicName},
                    {credType: 3, credTitle: "Логин", content: data.adminLogin},
                    {credType: 4, credTitle: "Пароль", content: data.adminPassword}
                ]
                setUserCredentials(Array.from(userData))
                setCurrentRole(data.officialRole)
            })
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const acceptChanges = () => {
        removeDangerMessage();
       const checkResult = checkUser(userCredentials)
       if(checkResult.result) {
            dangerMessage(checkResult.checkedArray)
       }
       else {
         const userResponse = {id: Number(userId), userData: {credentials: userCredentials, role: currentRole}}
         fetch(`${process.env.REACT_APP_API_URL}/alterUser`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userResponse),
        }).then(result => {
            setElementsDisplays(['none', 'flex'])
        })
       }
    }

    return (
        <Container fluid className='admin-decorator'>
            <div>
                <div className='admin-decorator__title'>
                    Изменить данные пользователя
                </div>
                <AdminUserPoint 
                    userCredentials={userCredentials}
                    setUserCredentials={setUserCredentials} 
                    acceptChanges={acceptChanges} 
                    elementStyle={elementsDisplays[0]}
                    currentRole={currentRole}
                    setCurrentRole={setCurrentRole}
                />
                <SuccessMessage 
                        title={'Пользователь'} 
                        savedTitle={'сохранен'} 
                        departmentTitle={'Пользователи'} 
                        changedTitle={'профиля пользователя'} 
                        displayStyle={elementsDisplays[1]}/>
            </div>
        </Container>
    )
}

export default AdminAlterUser;