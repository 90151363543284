
import {Container} from 'react-bootstrap';
import { useEffect, useState, useRef} from "react";
import {ImBin} from 'react-icons/im';
import {FaPlus} from 'react-icons/fa';

import CustomSwitch from "../../CustomSwitch";
import QuestionChooseOption from '../QuestionChooseOption';
import SaveButton from '../../SaveButton';
import removeDangerMessage from './RemoveDangerMessage';
import checkQuestions from './checkQuestions';
import dangerMessage from './DangerMessage';

import './QuestionBlock.scss';
import './QuestionTypes.scss';

//константы для различия по id вопросов из рядов таблиц и из строк
const TABLE_ROW_CHECK_VALUE = 1000; 
const TABLE_COLUMN_CHECK_VALUE = 2000;

export const QuestionBlock = ({questionId, handleChangeBlockNumber, removeQuestion, rememberSectionId, setCheckId, data=null}) => {

  const options = [
    {type: 0, label: 'Один из списка', text: null},
    {type: 1, label: 'Несколько из списка', text: null},
    {type: 2, label: 'Текст (строка)', text: 'Краткий ответ'},
    {type: 3, label: 'Текст (абзац)', text: 'Развернутый ответ'},
    {type: 4, label: 'Сетка (множественный выбор)', text: null},
    {type: 5, label: 'Сетка флажков', text: null}
  ]
    const [loading, setLoading] = useState('Сохранить'); //текст кнопки сохранения
    const [buttonSaveIsDisabled, setButtonSaveIsDisabled] = useState(false)
    const [selectedOption, setSelectedOption] = useState(0); //выбранный тип вопроса
    const[hasDifferent, setHasDifferent] = useState(false); //данные о том, имеет ли вопрос вариант "другое"
    const [hasRequired, setHasRequired] = useState(false); //даннве о том, является ли вопрос обязательным
    const [inputDisplay, setInputDisplay] = useState('flex'); //нужно ли показывать варианты "добавить вариант"
    const [tableDisplay, setTableDisplay] = useState('none'); //нужно ли показывать варинты таблицей
    const [titleContent, setTitleContent] = useState(null); //название вопроса
    const questionBlock = useRef(null); //ссылка на текущий вопрос
    const [answersList, setAnswersList] = useState([{type: selectedOption, classType: 0}]); //список всех вопросов типов 0 1 2 3 (не таблицы)
    const [tableRowList, setTableRowList] = useState([{type: null, classType: []}]); //список строк всех вопросов типа 4 5 (таблицы)
    const [tableColumnList, setTableColumnList] = useState([{type: null, classType: []}]); //список колонок всех вопросов типа 4 5 (таблицы)
    const [arrayTableRows, setArrayTableRows] = useState([]); //массив контента строк вопросов типа 4 5
    const [arrayTableColumns, setArrayTableColumns] = useState([]); //массив контента колонок вопросов типа 4 5
    const [arrayOptions, setArrayOptions] = useState([]); //список контента вопросов типа 0 1 
    const [questionNumber, setQuestionNumber] = useState(0); //порядковый номер вопроса
    const [isDisabled, setIsDisabled] = useState(true)
    const [questionContent, setQuestionContent] = useState(null)
    const [questionTrueId, setQuestionTrueId] = useState(null);
    const [handleChangedSelectedValue, setHandleChangedSelectedValue] = useState(true)

    //очистка всех массивов при выборе другого типа вопроса
    const clearState = () => { 
        setAnswersList([]);
        setTableRowList([]);
        setTableColumnList([]);
        setArrayTableRows([]);
        setArrayTableColumns([]);
        setArrayOptions([]);
    };

    //изменение типа вопроса
    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(Number(selectedValue));
        setHandleChangedSelectedValue(true);
    };

    useEffect(() => {
        if(handleChangedSelectedValue === true) {
            clearState();
            if(selectedOption < 2) {
                setInputDisplay('flex');
                setTableDisplay('none');
                addAnswers(selectedOption);
            }
            else if(selectedOption < 4){
                setInputDisplay('none');
                setTableDisplay('none');
                addAnswers(selectedOption);
            }
            else {
                setInputDisplay('none');
                setTableDisplay('flex');
                addTableRow();
                addTableColumn();
            }
        } 
    }, [selectedOption])

    //инициализация начального вида блока с вопросом при изменении типа вопроса
    useEffect(() => {
        if(data != null) {
            if(data.position !== 0) {
                setIsDisabled('none')
            }
            else {
                setIsDisabled(false)
            }
            setTitleContent(data.content.title)
            setQuestionTrueId(data.content.id)
            setQuestionNumber(Number(data.content.number));
            const selectedValue = Number(data.content.answerType)
            setHandleChangedSelectedValue(false);
            setSelectedOption(selectedValue);
            clearState()
            if(data.content.isRequired) {
                installSwitch()
                setHasRequired(data.content.isRequired)
            }

            if(selectedValue < 2) {
                setInputDisplay('flex');
                setTableDisplay('none');
                data.content.option.strings.map((element, i) => {
                    addAnswers(selectedValue, element.content, selectedValue)
                    // createArrayOptions(element.content, element.id)
                })
            } else if(selectedValue < 4) {
                setInputDisplay('none');
                setTableDisplay('none');
                addAnswers(selectedValue, null, selectedValue)
            } else {
                setInputDisplay('none');
                setTableDisplay('flex');
                data.content.option.strings.map((element, i) => {
                    addTableRow(element.content, selectedValue)
                    // createArrayTableRows(element.content, element.id)
                })
                data.content.option.columns.map((element, i) => {
                    addTableColumn(element.content, selectedValue)
                    // createArrayTableColumns(element.content, element.id)
                })
            }
            if(data.content.hasDifferent) {
                addAnswers("input__diff")
            }
        }
    }, [data])

    //формирование контента вопроса при любом изменении внутри него
    useEffect(() => {
        let result = null;
        if(selectedOption===0 ||  selectedOption===1) {
            result = arrayOptions;
        }
        else if(selectedOption===4 || selectedOption===5) {
            result = {strings: arrayTableRows, columns: arrayTableColumns}
        }
        setQuestionContent({
            optionsList: result, 
            answerType: selectedOption, 
            title: titleContent, 
            different: hasDifferent, 
            required: hasRequired,
            sectionId: rememberSectionId,
            number: Number(questionNumber)
        });
    }, [arrayOptions, arrayTableRows, arrayTableColumns, titleContent, hasDifferent, hasRequired, questionNumber])

    const checkAndPrepareQuestion = () => {
        removeDangerMessage()
        const objectNeedsChanges = checkQuestions(questionContent) //проверка контента секции на ошибки
        if(objectNeedsChanges) {
            setLoading("Попробовать еще раз")
            dangerMessage(questionContent)
        }
        else {
            if(data == null) {
            //формирование объекта вопросов
            fetch(`${process.env.REACT_APP_API_URL}/addNewQuestion${questionContent.answerType}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({id: questionTrueId, content: questionContent}),
            }).then((response) => {
                if(response.status == 200) {
                    response.text().then(text => {
                        const data = text && JSON.parse(text);
                        setQuestionTrueId(data)
                        setCheckId({id: questionId, checkId: data})
                    });
                    setLoading("Сохранено")
                    setButtonSaveIsDisabled(true)
                    setIsDisabled(false)
                }
                else {
                    setLoading('Что-то пошло не так')
                }
            })
        } else {
            if(Number(data.content.answerType) !== questionContent.answerType){
                fetch(`${process.env.REACT_APP_API_URL}/removeQuestion${data.content.answerType}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(questionTrueId),
                }).then((response) => {
                    if(response.status === 200) {}
                }).then(
                    fetch(`${process.env.REACT_APP_API_URL}/addNewQuestion${questionContent.answerType}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({id: questionTrueId, content: questionContent}),
                    }).then((response) => {
                        if(response.status === 200) {
                            response.text().then(text => {
                                const data = text && JSON.parse(text);
                                setQuestionTrueId(data)
                                setCheckId({id: questionId, checkId: data})
                            });
                            setLoading("Сохранено")
                            if(data.position !== 0) {
                                setIsDisabled('none')
                            }
                            else {
                                setIsDisabled(false)
                            }
                            setButtonSaveIsDisabled(true)
                        }
                        else {
                            setLoading('Что-то пошло не так')
                        }
                    })
                )
            } else {
            fetch(`${process.env.REACT_APP_API_URL}/editQuestion${questionContent.answerType}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({id: questionTrueId, content: questionContent}),
            }).then((response) => {
                if(response.status === 200) {
                    response.text().then(text => {
                        const data = text && JSON.parse(text);
                        setCheckId({id: questionId, checkId: data})
                    });
                    setLoading("Сохранено")
                    if(data.position !== 0) {
                        setIsDisabled('none')
                    }
                    else {
                        setIsDisabled(false)
                    }
                    setButtonSaveIsDisabled(true)
                }
                else {
                    setLoading('Что-то пошло не так')
                }
            })
        }}}
    }

    //добавление новой опции в массив вопросов типа 0 1 2 3
    const addAnswers = (optionType, optionData=null, handlerForEditing = null) =>  {
        if(optionType === 'input__diff') {
            setHasDifferent(true);
        }
        setAnswersList(current => [...current, {type: handlerForEditing == null ? selectedOption : handlerForEditing, classType: optionType, data: optionData}]);
    }

    //добавление новой опции в массив рядов таблиц
    const addTableRow = (optionData=null, handlerForEditing = null) => {
        setTableRowList(current => [...current, {type: handlerForEditing == null ? selectedOption : handlerForEditing, classType: selectedOption, data: optionData}]);
    }

    //добавление новой опции в массив колонок таблиц
    const addTableColumn = (optionData=null, handlerForEditing = null) => {
        setTableColumnList(current => [...current, {type: handlerForEditing == null ? selectedOption : handlerForEditing, classType: selectedOption, data: optionData}]);
    }


    //инициализаия котнента новой опции в рядах таблиц
    const createArrayTableRows = (optionValue, id) => {
        if(arrayTableRows.find(item => item.id===id)) {
            const updateTableRows = arrayTableRows.map((elem) => (
                elem.id !== id ? elem : {content: optionValue, id: id}
            ))
            setArrayTableRows(updateTableRows);
        }
        else {setArrayTableRows(current => [...current, {content: optionValue, id: id}])}
    }

    //инициализаия котнента новой опции в колонках таблиц
    const createArrayTableColumns = (optionValue, id) => {
        if(arrayTableColumns.find(item => item.id===id)) {
            const updateTableColumns = arrayTableColumns.map((elem) => (
                elem.id !== id ? elem : {content: optionValue, id: id}
            ))
            setArrayTableColumns(updateTableColumns);
        }
        else {setArrayTableColumns(current => [...current, {content: optionValue, id: id}])}
    }

    //инициализаия котнента новой опции в вопросах типа 0 1 
    const createArrayOptions = (optionValue, id) => {
        if(optionValue != null) {
            if(arrayOptions.find(item => item.id===id)) {
                const updateOptions = arrayOptions.map((elem) => (
                    elem.id !== id ? elem : {content: optionValue, id: id}
                ))
                setArrayOptions(updateOptions);
            }
            else {
                setArrayOptions(current => [...current, {content: optionValue, id: id}])
            }
        }
    }

    //удаление опции
    const removeOption = (e, blockId, optionType, classType) => {
        e.currentTarget.parentNode.remove()
        switch(optionType) {
            case 'option':
                const optionArray = arrayOptions.filter(item => item.id !== blockId)
                setArrayOptions(optionArray)
                break;
            case 'table-row':
                const optionTableRowArray = arrayTableRows.filter(item => item.id !== blockId)
                setArrayTableRows(optionTableRowArray)
                break;
            case 'table-column':
                const optionTableColumnsArray = arrayTableColumns.filter(item => item.id !== blockId)
                setArrayTableColumns(optionTableColumnsArray)
                break;
            default: 
                break;
        }
        if(classType==='input__diff') {setHasDifferent(false)}
    }

    //удаление вопроса (из DOM-дерева)
    const handleChangeRemoveQuestion = () => {
        if(questionTrueId !== null) {
            fetch(`${process.env.REACT_APP_API_URL}/removeQuestion${questionContent.answerType}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(questionTrueId),
            }).then((response) => {
                if(response.status === 200) {
                    questionBlock.current.remove();
                    removeQuestion(questionId)
                }
            })
        }
        else {
            questionBlock.current.remove();
            removeQuestion(questionId)
        }
    }

    //измнение состояния обязательности вопроса
    const installSwitch = () => {
        setHasRequired(prev => !prev)
    }

    const handleAddQuestion = () => {
        setIsDisabled('none')
        handleChangeBlockNumber()
    }

    return (
        <div style={{marginTop: 30+'px'}}  ref={questionBlock}>
        <div className='question-block__alert-message' id={questionId}>
            Обратите внимание на пустые поля!
        </div>
        <Container fluid className='question-block' id={questionId}>
            <div className='question-block__container'>
                <div>
                    <input className='question-block__container__number' 
                        type='number' 
                        value={questionNumber == null ? 0 : questionNumber} 
                        placeholder="0"
                        onChange={(e) => {setQuestionNumber(e.target.value)}}/>
                    <input className='question-block__container__title' 
                        type='text' 
                        value={titleContent == null ? "" : titleContent} 
                        placeholder="Заголовок" 
                        onChange={(e) => {setTitleContent(e.target.value)}}/>
                </div>
                <select className="question-block__container__select" value={selectedOption} onChange={(e) => handleChange(e)}>
                    {options.map((item, i) => {return <option key={i} value={item.type == null ? 0 : item.type}>{item.label}</option>})}
                </select>
            </div>
            <div className="question-block__content">
                <div>
                    {answersList.map((answer, i) => (
                        <div key={i}>
                            <div className="question-block__string" style={{width: '40%'}}>
                                <QuestionChooseOption id={i} 
                                    data={answer.data}
                                    type={answer.type} 
                                    classType={answer.classType} 
                                    checkFunction={createArrayOptions} 
                                    checkRemoveWord={'option'}
                                    removeAnswer={removeOption}
                                />
                            </div>
                        </div>)
                    )}
                    <div className='question-block__different-option' style={{display: inputDisplay}}> 
                            <div className='question-block__different-option__add' onClick={() => {addAnswers(selectedOption)}} >Добавить вариант</div> 
                            или 
                            <div className='question-block__different-option__add-diff' onClick={() => {addAnswers('input__diff')}}>добавить вариант "Другое"</div>
                    </div>
                </div>  
                <div className="question-block__block" style={{display: tableDisplay}}>
                    <ul>
                        <div className='question-block__block__row'>Строки</div>
                        {tableRowList.map((answer, i) => (
                            <li key={i}>
                                <div className="question-block__string">
                                    <QuestionChooseOption id={i+TABLE_ROW_CHECK_VALUE} 
                                        data={answer.data}
                                        type={answer.type} 
                                        classType={answer.classType} 
                                        checkFunction={createArrayTableRows} 
                                        checkRemoveWord={'table-row'}
                                        removeAnswer={removeOption}
                                    />
                                </div>
                            </li>)
                        )}
                        <div className='question-block__different-option__table'>
                            <div className='question-block__different-option__table__add' onClick={() => {addTableRow()}} >Добавить вариант</div>
                        </div>
                    </ul>
                    <ul >
                        <div className='question-block__block__row'>Столбцы</div>
                        {tableColumnList.map((answer, i) => (
                            <li key={i}>
                                <div className="question-block__string">
                                    <QuestionChooseOption id={i+TABLE_COLUMN_CHECK_VALUE} 
                                        data={answer.data}  
                                        type={answer.type} 
                                        classType={answer.classType} 
                                        options={options} 
                                        checkFunction={createArrayTableColumns} 
                                        checkRemoveWord={'table-column'}
                                        removeAnswer={removeOption}
                                    />
                                </div>
                            </li>)
                        )}
                        <div className='question-block__different-option__table'>
                            <div className='question-block__different-option__table__add' onClick={() => {addTableColumn()}} >Добавить вариант</div>
                        </div>
                    </ul>
                </div>
            </div>
            <div className='question-block__footer'>
                <SaveButton
                        saveChanges={checkAndPrepareQuestion} 
                        responseText={loading}
                        currentClassName={'question-button'}
                        isCurrentDisabled={buttonSaveIsDisabled}/>
                <div className="question-block__footer__container">
                    <div className='question-block__footer__icons'>
                        <ImBin onClick={() => {handleChangeRemoveQuestion()}}/>
                    </div>
                    <div className='question-block__footer__is-required'>
                        Обязательный вопрос
                        <CustomSwitch defineSwitch={installSwitch} isChecked={hasRequired} />
                    </div>
                </div>
            </div>
        </Container>
        <button className={`admin__form__button admin__form__button__plus__${isDisabled}`} onClick={() => handleAddQuestion()} disabled={isDisabled}>
            <FaPlus/>
        </button>
    </div>
    )
}

export default  QuestionBlock;
