import * as React from 'react'

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useLayoutEffect, useState} from "react";

import ChangeButtons from '../../../components/UI/ChangeButtons';
import {removeItemFunction} from "../AdminWrapper/RemoveItem";
import {cancelRemovingFunction} from "../AdminWrapper/CancelRemoving";

const AdminArticles = () => {

    const authUser = useSelector(x => x.auth.user);
    
    const [articles, setArticles] = useState([]);
    
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllArticles`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const articlesData = data.map((article) => ({
                    id: article.id, 
                    title: article.title,
                    description: article.description,
                    link: article.linkAddress,
                    articleKind: article.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                setArticles(articlesData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const removeArticle = (currentId) => {
        const alterArticle = articles.map(article => article.id !== currentId ? article : {
            id: article.id, 
            title: article.title,
            description: article.description,
            link: article.link,
            articleKind: ['none', 'block', 'block']
        })
        setArticles(alterArticle)
        removeItemFunction(currentId, 'removeArticle', authUser)
    }

    const cancelRemovingArticle = (currentId) => {
        const alterArticle = articles.map(article => article.id !== currentId ? article : {
            id: article.id, 
            title: article.title,
            description: article.description,
            link: article.link,
            articleKind: ['block', 'block', 'none']
        })
        setArticles(alterArticle)
        cancelRemovingFunction(currentId, 'cancelRemovingArticle', authUser)
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Полезные материалы
            </div>
            <div className="admin__btn-group"> 
                <Link to={'/admin/articles/newArticle'} className='admin__button'>Добавить материал</Link>
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th>Название материала</th>
                        <th>Краткое описание</th>
                        <th>URL</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {articles && articles?.map((item) => {
                    return <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.description}</td>
                                <td>{item.link}</td>
                                <td style={{textAlign: 'right'}}>
                                    <ChangeButtons 
                                        itemId={item.id} 
                                        removeFunction={removeArticle} 
                                        cancelFunction={cancelRemovingArticle} 
                                        linkAlter={String(item.id)}
                                        itemStatus={item.articleKind}/>
                                </td>
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminArticles;