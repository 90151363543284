
import {Container} from 'react-bootstrap';
import {QuestionBlock} from '../QuestionBlock/index';
import {FaMinus} from 'react-icons/fa';
import {useState, useRef, useEffect} from "react";

import SaveButton from '../../SaveButton';
import AddElementButton from '../../AddElementButton';

import "./QuestionSection.scss";

const QuestionSection = ({sectionId, sectionCurrentNumber, sectionNumber, removeSection, handleChangeSectionNumber, currentFormId, constructSectionQuestions, data=null}) => {

    //дисплеи элементов
    const [elementsDisplay, setElementsDisplay] = useState({sectionDisplay: 'block', questionsDisplay: "none"})

    //текст кнопки сохранения
    const [loading, setLoading] = useState('Сохранить секцию');

    //данные кнопок
    const [addSectionButtonStatus, setAddSectionButtonStatus] = useState({isCurrentDisabled: true, currentDisplay: 'flex'})
    const [addQuestionButtonStatus, setAddQuestionButtonStatus] = useState({isCurrentDisabled: true, currentDisplay: 'flex'})

    //цифровые значения
    const [currentId, setCurrentId] = useState(sectionId*100+101); //текущий id секции
    const [questionListLength, setQuestionListLength] = useState(0)

    //массив всех вопросов и контента вопросов
    const [questionList, setQuestionList] = useState([]); //массив секций
    const [questionsCheckId, setQuestionsCheckId] = useState([])
    
    //не цифровые данные
    const sectionBlock = useRef(null); //ссылка на текущий блок
    const [titleContent, setTitleContent] = useState(null); //название секции
    const [descriptionContent, setDescriptionContent] = useState(null); //описание секции
    const [currentSectionId, setCurrentSectionId] = useState(null)

    useEffect(() => {
        constructSectionQuestions({checkId: currentSectionId, artificialId: sectionId, contentIds: questionsCheckId});
    }, [currentSectionId, questionsCheckId])


    //добавление вопроса
    const handleChangeBlockNumber = () => {
        setQuestionsCheckId((current) => [...current, {id: currentId, checkId: null}])
        setCurrentId(prev => prev+1)
        setQuestionList((current) => [...current, {id: currentId}])
        setQuestionListLength(prev => prev+1)
    }

    //удление вопроса (из массива контента вопросов)
    const handleChangeRemoveBlock = (idToRemove) => {
        setQuestionListLength(prev => prev-1);
        const updatedQuestionsCheckId = questionsCheckId.filter((question) => question.id !== idToRemove)
        setQuestionsCheckId(updatedQuestionsCheckId)
        if(questionListLength === 1 || idToRemove === questionsCheckId[questionsCheckId.length-1].id) {
            setAddQuestionButtonStatus({isCurrentDisabled: false, currentDisplay: 'flex'})
        }
    }

    //удаление секции (из DOM-дерева)
    const handleChangeRemoveSection = (sectionId) => {
        if(currentSectionId !== null) {
            fetch(`${process.env.REACT_APP_API_URL}/removeSection`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(currentSectionId),
            }).then((response) => {
                if(response.status == 200) {
                    sectionBlock.current.remove()
                    removeSection(sectionId)
                    setCurrentSectionId(null)
                }
            })
        }
        else {
            sectionBlock.current.remove()
            removeSection(sectionId)
            setCurrentSectionId(null)
        }
    }

    //добавление секции
    const handleChangeSection = () => {
        handleChangeSectionNumber()
        setAddSectionButtonStatus({isCurrentDisabled: false, currentDisplay: 'none'})
    }

    //добавление вопроса
    const handleChangeQuestionList = () => {
        setQuestionList((current) => [...current, {id: sectionId*100+100+currentId, memorySectionId: currentSectionId, data: null}])
        setQuestionsCheckId((current) => [...current, {id: sectionId*100+100+currentId, checkId: null}])
        setQuestionListLength(1)
        setAddQuestionButtonStatus({isCurrentDisabled: false, currentDisplay: 'none'})
    }

    const handleChangePrevQuestionList = (element, currentNumber, length) => {
        const isTheLastInList = length-currentNumber-1;
        setQuestionList((current) => [...current, {id: sectionId*100+100+currentId, memorySectionId: currentSectionId, data: {content: element, position: isTheLastInList}}])
        setQuestionsCheckId((current) => [...current, {id: sectionId*100+100+currentId, checkId: null}])
        setQuestionListLength(1)
        setAddQuestionButtonStatus({isCurrentDisabled: false, currentDisplay: 'none'})
    }
    

    //сохранение секции
    const checkAndPrepareSection = () => {
        if(titleContent === null || titleContent.trim() === '') {
            setLoading("Заполните обязательные поля!")
        }
        else {
            //формирование объекта секции
            fetch(`${process.env.REACT_APP_API_URL}/addNewSection`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({id: currentSectionId, title: titleContent, description: descriptionContent, formId: currentFormId}),
            }).then((response) => {
                if(response.status == 200) {
                    response.text().then(text => {
                        const data = text && JSON.parse(text);
                        setCurrentSectionId(data)
                    });
                    setLoading("Сохранено")

                    if(data != null) {
                        if(sectionCurrentNumber >= sectionNumber){
                            setAddSectionButtonStatus({isCurrentDisabled: false,  currentDisplay: 'flex'})
                        }
                    } else {
                        setAddSectionButtonStatus({isCurrentDisabled: false,  currentDisplay: 'flex'})
                    }
                    if(questionList.length === 0) {
                        setAddQuestionButtonStatus({isCurrentDisabled: false,  currentDisplay: 'flex'})
                    }
                }
                else {
                    setLoading("Что-то пошло не так")
                }
            })
        }
    }

    //установить проверочное id при сохранении вопроса
    const handleChangeCheckIds = (savedQuestion) => {
        const updatedQuestionsId = questionsCheckId.map((question) => 
            question.id === savedQuestion.id ? {id: savedQuestion.id, checkId: savedQuestion.checkId} : question)
        setQuestionsCheckId(updatedQuestionsId)
    }

    //перейти к вопросам
    const goForth = () => {
        setElementsDisplay({sectionDisplay: 'none', questionsDisplay: "block"})
    }

    //перейти к данным секции
    const goBack = () => {
        setElementsDisplay({sectionDisplay: 'block', questionsDisplay: "none"})
    }

    useEffect(() => {
        if(data != null) {
            setTitleContent(data.title)
            setDescriptionContent(data.description)
            setCurrentSectionId(data.id)
            setAddQuestionButtonStatus({isCurrentDisabled: false,  currentDisplay: 'flex'})
         
            if(sectionCurrentNumber === sectionNumber) {
                setAddSectionButtonStatus({isCurrentDisabled: false,  currentDisplay: 'flex'})
            } else {
                setAddSectionButtonStatus({isCurrentDisabled: true,  currentDisplay: 'none'})
            }
            if(data.questionList != null && data.questionList.length != null) {
                data.questionList.map((element, i) => (
                    handleChangePrevQuestionList(element, i, data.questionList.length)
                ))
            }
        }
    }, [data])

    return (
        <div ref={sectionBlock} style={{marginTop: 3+'vh'}}>
            <div className='question-section__alert-message' id={sectionId}>
                Пожалуйста, заполните обязательные поля!
            </div>
            <Container fluid className="question-section__manage">
                <div className="question-section__manage__title">
                    Секция {sectionCurrentNumber} из {sectionNumber}
                </div>
                <div  className='admin__form__button-light' onClick={() => {handleChangeRemoveSection(sectionId)}}>
                    <FaMinus/>
                </div>
            </Container>
            <Container fluid className='question-section'>
                <div style={{display: elementsDisplay.sectionDisplay}}>
                    <div className='question-section__title'>
                        Название <span style={{color: 'rgba(228, 29, 55, 0.8)'}}>(обязательно)</span>
                    </div>
                    <input className='question-section__input' 
                            type='text' 
                            value={titleContent == null ? "" : titleContent}
                            placeholder="Новая секция..." 
                            onChange={(e) => {setTitleContent(e.target.value)}}/>
                    <div className='question-section__title'>
                        Описание
                    </div>
                    <textarea className='question-section__input' 
                            type='text' 
                            value={descriptionContent == null ? "" : descriptionContent}
                            placeholder="Описание секции..." 
                            onChange={(e) => {setDescriptionContent(e.target.value)}}/>
                    <SaveButton 
                        saveChanges={checkAndPrepareSection} 
                        responseText={loading}
                        currentClassName={'active'}
                        isCurrentDisabled={false}/>
                    <SaveButton 
                        saveChanges={goForth} 
                        responseText={"Перейти к вопросам"}
                        currentClassName={'active'}
                        isCurrentDisabled={false}/>
                </div>
                <div style={{display: elementsDisplay.questionsDisplay}}>
                    <div className='admin__form__container'>
                        <div className='question-section__title' style={{marginBottom: 0}}>
                            Вопросы <span style={{color: 'rgba(228, 29, 55, 0.8)'}}>(секция должна содержать хотя бы 1 вопрос)</span>
                        </div>
                    </div>
                    <AddElementButton 
                        addElement={handleChangeQuestionList} 
                        addElementText={'Добавить вопросы'} 
                        isCurrentDisabled={addQuestionButtonStatus.isCurrentDisabled}
                        currentDisplay={addQuestionButtonStatus.currentDisplay}/>
                    {questionList && questionList?.map((block, i) => (
                        <QuestionBlock key={i} 
                            id={block.id} 
                            data={block.data}
                            questionId={block.id} 
                            rememberSectionId={currentSectionId}
                            removeQuestion={handleChangeRemoveBlock} 
                            setCheckId={handleChangeCheckIds}
                            handleChangeBlockNumber={handleChangeBlockNumber}/>
                    ))}
                    <SaveButton 
                        saveChanges={goBack} 
                        responseText={"Перейти к секции"}
                        currentClassName={'active'}
                        isCurrentDisabled={false}/>
                </div>
            </Container>
            <AddElementButton 
                addElement={handleChangeSection} 
                addElementText={'Добавить секцию'} 
                isCurrentDisabled={addSectionButtonStatus.isCurrentDisabled}
                currentDisplay={addSectionButtonStatus.currentDisplay}/>
        </div>
    )
}
export default  QuestionSection;