import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useLayoutEffect, useState} from "react";

import ChangeButtons from "../../../components/UI/ChangeButtons";
import splitTime from '../../../components/UI/Functions/splitTime';
import ModalWindow from '../../../components/UI/ModalWindow';
import cutStrings from '../../../components/UI/CutText';

const AdminPagesTexts = () => {

    const [isModal, setModal] = useState([]);
    const [isModalAgree, setModalAgree] = useState([]);
    const [agreeText, setAgreeText] = useState([])
    const [textContent, setTextContent] = useState([]);

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllTexts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(''),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedData = data.map((item) => ({
                    id: item.id,
                    pageTitle: item.pageTitle,
                    title: item.title,
                    content: cutStrings(item.textContent),
                    fullContent: item.textContent,
                    time: item.changingTime === undefined ? " " : splitTime(item.changingTime),
                    itemVisible: 'false'
                }))
                const changedModal = Array(changedData.length).fill('none')
                setModal(changedModal)
                setTextContent(changedData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
        fetch(`${process.env.REACT_APP_API_URL}/getAgreePlainText`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(''),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedData = [{
                    id: data.id,
                    pageTitle: "Анкетирование",
                    title: "Согласие на обработку данных",
                    content: cutStrings(data.content),
                    fullContent: data.content,
                    time: data.changingTime === undefined ? " " : splitTime(data.changingTime),
                    itemVisible: 'false'
                }]
                const changedModal = Array(changedData.length).fill('none')
                setModalAgree(changedModal)
                setAgreeText(changedData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const changeModal = (itemId) => {
        const changedArray = isModal.map((item, i) => 
            i === itemId ? 'flex' : 'none'
        )
        setModal(changedArray)
    }

    const closeModal = () => {
        const changedArray = Array(isModal.length).fill('none')
        setModal(changedArray)
    }

    const changeModalAgree = (itemId) => {
        const changedArray = isModalAgree.map((item, i) => 
            i === itemId ? 'flex' : 'none'
        )
        setModalAgree(changedArray)
    }

    const closeModalAgree = () => {
        const changedArray = Array(isModalAgree.length).fill('none')
        setModalAgree(changedArray)
    }

    const removeText = (currentId, artificialId) => {}
    const cancelRemovingText = (currentId, artificialId) => {}

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Тексты страниц
            </div>
            <table className='admin__table'>
                <thead>
                    <tr>
                        <th style={{width: 7+"%"}}>ID</th>
                        <th>Страница</th>
                        <th>Заголовок</th>
                        <th>Контент</th>
                        <th>Дата последних изменений</th>
                        <th style={{width: 11+"%"}}>Редактировать</th>
                    </tr>
                </thead>
                <tbody>
                {textContent?.map((text, index) => {
                    return <tr key={index}>
                                <td>{index+1}</td>
                                <td>{text.pageTitle}</td>
                                <td>{text.title}</td>
                                <td onClickCapture={() => changeModal(index)}>
                                <ModalWindow
                                    isVisible={isModal[index]}
                                    title={text.title}
                                    content={text.fullContent}
                                    onClose={() => closeModal()}
                                />
                                {text.content}</td>
                                <td>{text.time}</td>
                                { <td>
                                    <ChangeButtons 
                                    itemId={text.id} 
                                    artificialId={index === text.id ? (index+1)*100 : index}
                                    removeFunction={removeText} 
                                    cancelFunction={cancelRemovingText} 
                                    linkAlter={String(text.id)}
                                    itemStatus={['none', 'flex', 'none']}/>
                                </td>}
                            </tr>
                })}
                {agreeText?.map((text, index) => {
                    return <tr key={index}>
                                <td>{textContent.length+index+1}</td>
                                <td>{text.pageTitle}</td>
                                <td>{text.title}</td>
                                <td onClickCapture={() => changeModalAgree(index)}>
                                <ModalWindow
                                    isVisible={isModalAgree[index]}
                                    title={text.title}
                                    content={text.fullContent}
                                    onClose={() => closeModalAgree()}
                                />
                                {text.content}</td>
                                <td>{text.time}</td>
                                { <td>
                                    <ChangeButtons 
                                        itemId={text.id} 
                                        artificialId={index === text.id ? (index+textContent.length+1)*100 : index+textContent.length+1}
                                        removeFunction={removeText} 
                                        cancelFunction={cancelRemovingText} 
                                        linkAlter={'agree'+String(text.id)}
                                        itemStatus={['none', 'flex', 'none']}/>
                                </td>}
                            </tr>
                })}
                </tbody>
            </table>
        </Container>
    )
}

export default AdminPagesTexts;
