
import {FaPlus} from 'react-icons/fa';
import "./AddElementButton.scss";

const AddElementButton = ({addElement, addElementText, isCurrentDisabled=false, currentDisplay='flex'}) => {

     return (
        <div className={`add-section add-section__${isCurrentDisabled}`} style={{display: currentDisplay}} >
                <div className='add-section__title' style={{marginBottom: 0}}>
                    {addElementText}
                </div>
                <button className='add-section__button' onClick={() => addElement()} disabled={isCurrentDisabled}>
                    <FaPlus/>
                </button>
            </div>
     )
 }
 
 export default AddElementButton;