
import {Outlet} from "react-router-dom"

import TopNavigation from '../../components/navigation/TopNavigation';
import Footer from '../../components/navigation/Footer';

import "./MainDashboardLayout.scss"

const MainDashboardLayout = () => {

   
    return (
        <div>
            <TopNavigation/>
            <div className='main-dashboard__content'>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    )
}

export default MainDashboardLayout;