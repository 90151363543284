import * as React from 'react';
import {useState, useLayoutEffect} from "react";
import { Container, Row } from 'react-bootstrap';
import PersonCard from '../../../components/UI/PersonCard/PersonCard';
import '../SecondLevel.scss';


const Persons = () => {

    const [persons, setPersons] = useState([])

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getAllActiveMembers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const membersData = data.map((member) => ({
                    id: member.id, 
                    lastName: member.lastName, 
                    firstName: member.firstName, 
                    patronymicName: member.patronymicName, 
                    role: member.role,
                    photo: member.image,
                }))
                setPersons(membersData)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    
    return (
        <div className='second-level'>
            <Container fluid className='second-level__content'>
                <Container className='second-level__title'>
                    <div className='second-level__title--text'>
                        Персоналии
                    </div> 
                </Container>
                <Container className='second-level__persons'>
                    <Row className='second-level__persons__block'>
                        {persons?.map((person, index) => (
                            <PersonCard key={index} person={person} linkAlter={'/persons/'+person.id}/>
                        ))}
                    </Row>
                </Container>
            </Container>
        </div>
        
    )
}

export default Persons;