import * as React from 'react';
import {useState, useLayoutEffect} from "react";
import {Container} from 'react-bootstrap';
import '../SecondLevel.scss';

const AboutProject = () => {

    const [pageText, setPageText] =useState({__html: ''})
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getTextByTitleAsHtml`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({title: 'О проекте', pageTitle: 'О проекте'}),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setPageText({__html: data.textContent})
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    
    return (
        <div className='second-level'>
            <Container fluid className='second-level__content'>
                <Container className='second-level__title'>
                    <div className='second-level__title--text'>
                    О проекте
                    </div> 
                </Container>
                <Container className='second-level__description'>
                    <p className='second-level__description--text' dangerouslySetInnerHTML={pageText}/>
                </Container>
            </Container>
        </div>
        
    )
}

export default AboutProject;