
import {useRef, useState} from "react";
import { useEffect} from "react";
import {BsXLg} from 'react-icons/bs';

import "./DownloadImages.scss";

const DownloadImages = ({marginStyle, title, resultStatus="", getImages, data = null, oneOrMany = 0}) => {
    const refInput = useRef(null);
    const [imageId, setImageId] = useState(0);
    const [imagesArray, setImagesArray] = useState([]);
    const [imagesURLs, setImagesURLs] = useState([]);

    useEffect(() => {
        getImages(imagesArray);
    }, [imagesArray])

    function urltoFile(data){
        return data.map((element) => {
            return (fetch(element.url)
            .then(function(res){
                return res.arrayBuffer();
            })
            .then(function(buf){
                const image =  new File([buf], element.filename,{type:element.mimeType});
                var dt = new DataTransfer();
                dt.items.add(image);
                const imagesList = dt.files;
                return imagesList 
            })
        );
        })
    }

    useEffect(() => {
        if(data != null && data.length !== 0) {
            const listForModifying = data.map((element, i) => ({
                url: element.src, 
                filename: i + '.txt', 
                mimeType: 'text/plain'
            }))
            const listForShowing = data.map((element, i) => ({
                id: element.id,
                src: element.src
            }))
        
            let dataList = urltoFile(listForModifying)
            Promise.all(dataList).then(results => {
                results.map((element) => {
                    setImagesArray([{id: imageId, imageValue: results[0]}]);
                    setImageId(prev => prev+1);
                    setImagesURLs(listForShowing)
                    setImageId(listForShowing.length)
                    return element;
            })})
        }
    }, [data])

    const handleAddImages = (e) => {
        if(e.target.files.length > 0 && oneOrMany === 0)
        {
            const newImagesUrls = URL.createObjectURL(e.target.files.item(0));
            setImagesURLs(current => [...current, {id: imageId, src: newImagesUrls}]);
            setImagesArray(current => [...current, {id: imageId, imageValue: e.target.files}]);
            setImageId(prev => prev+1);
        }

        if(e.target.files.length > 0 && oneOrMany === 1)
        {
            const newImagesUrls = URL.createObjectURL(e.target.files.item(0));
            setImagesURLs([{id: imageId, src: newImagesUrls}]);
            setImagesArray([{id: imageId, imageValue: e.target.files}]);
            setImageId(prev => prev+1);
        }
  };

  const removeImage = (e, idValue) => {
    e.currentTarget.parentNode.remove();
    const updatedImagesarray = imagesArray.filter(element => element.id !== idValue)
    setImagesArray(updatedImagesarray);
  }

    return (
        <div className="file-download" style={marginStyle}>
            <div className='file-download__title'>
                {title} <span style={{color:'#E41D37'}}>{resultStatus}</span>
            </div>
            <div className="file-download__block">
                <input type="file" ref={refInput} className="form-control file-download__input" accept="image/*" onChange={(e) => {handleAddImages(e)}}/>
                <div className="file-download__list">
                    {imagesURLs.map((item, i) => 
                        <div key={i} className='file-download__string'>
                            <img className="file-download__image" src={item.src} alt="question"/>
                            <BsXLg style = {{display: oneOrMany === 1 ? "none" : "block"}} className="file-download__table__icon" onClick={(e) => {removeImage(e, item.id)}}/> 
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default DownloadImages;