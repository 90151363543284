import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useLayoutEffect, useState} from "react";
import {Droppable, DragDropContext} from "react-beautiful-dnd";
import {useSelector} from 'react-redux';

import StringWithChanges from "../../../components/UI/StringWithChanges";
import {removeItemFunction} from "../AdminWrapper/RemoveItem";
import {cancelRemovingFunction} from "../AdminWrapper/CancelRemoving";

const AdminMenu = () => {

    const authUser = useSelector(x => x.auth.user);

    const [menuContent, setMenuContent] = useState([]);

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
      });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getMenuPointsResponse`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify('test'),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedData = data.map((pointItem) => ({
                    id: pointItem.id, 
                    title: pointItem.pointTitle, 
                    link: pointItem.linkAddress, 
                    pointKind: pointItem.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                const sortedPoints = changedData.sort((a,b) => a.id - b.id);
                setMenuContent(sortedPoints)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
        });
    }, [setAppState])

    const removeMenuPoint = (currentId) => {
        const alterMenuContent = menuContent.map(pointItem => pointItem.id !== currentId ? pointItem : {
            id: pointItem.id, 
            title: pointItem.title, 
            link: pointItem.link, 
            pointKind: ['none', 'block', 'block']
        })
        setMenuContent(alterMenuContent)
        removeItemFunction(currentId, 'removingPoint', authUser)
    }

    const cancelRemovingMenuPoint = (currentId) => {
        const alterMenuContent = menuContent.map(pointItem => pointItem.id !== currentId ? pointItem : {
            id: pointItem.id, 
            title: pointItem.title, 
            link: pointItem.link, 
            pointKind: ['block', 'block', 'none']
        })
        setMenuContent(alterMenuContent)
        cancelRemovingFunction(currentId, 'cancelRemovingPoint', authUser)
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const copyList = [...menuContent];
        const firstElement = copyList[result.source.index]
        const alterList = copyList.filter(item => item.id !== firstElement.id)
        alterList.splice(result.destination.index, 0, firstElement)
        const objectToSend = alterList.map(pointItem => pointItem.id)
        fetch(`${process.env.REACT_APP_API_URL}/updateMenuPointsOrder`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objectToSend),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                const changedData = data.map((pointItem) => ({
                    id: pointItem.id, 
                    title: pointItem.pointTitle, 
                    link: pointItem.linkAddress, 
                    pointKind: pointItem.isActive ? ['block', 'block', 'none'] : ['none', 'block', 'block']
                }))
                const sortedPoints = changedData.sort((a,b) => a.id - b.id);
                setMenuContent(sortedPoints)
            })
        })
    }

    return (
        <Container fluid className='admin-decorator'>
            <div className='admin-decorator__title'>
                Пункты меню
            </div>
            <div className="admin-menu">
                <div className='admin-menu__title'>
                    Главная
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='admin-menu__names'>
                        {(provided) => (
                            <div className='admin-menu__names'
                                {...provided.droppableProps} 
                                ref={provided.innerRef}>
                                    {menuContent && menuContent?.map((point, i) => {
                                        return <StringWithChanges key={i} 
                                            stringPoint={point} 
                                            artificialId={i === point.id ? (i+1)*100 : i}
                                            classes={['name__point-title']} 
                                            functionRemove={removeMenuPoint}
                                            functionCancel={cancelRemovingMenuPoint}
                                            linkAlter={String(point.id)}
                                            itemStatus={point.pointKind}
                                    />})}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Container>
    )
}

export default  AdminMenu ;