import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState, useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';

import SuccessMessage from '../../../components/UI/SuccessMessage';

import '../AdminPage.scss'
import '../AdminAdd/AdminAdd.scss';


const AdminAlterPhone = () => {

    let {phoneId} = useParams();

    const [phoneContent, setPhoneContent] = useState('')
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])
    
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/getPhoneById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(Number(phoneId)),
        }).then((response) => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                setPhoneContent(data.phone)
            });
            setAppState({ loading: false, repos: response});
        }).catch(function(error) {
            console.log(error)
         })
    }, [setAppState])


    const handleChangePhone = (e) => {
        const newValue = e.target.value
        setPhoneContent(newValue)
    }

    const acceptChanges = () => {
        const phoneResponse = {id: Number(phoneId), content: phoneContent}
        fetch(`${process.env.REACT_APP_API_URL}/alterPhone`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(phoneResponse),
        }).then(result => {
            setElementsDisplays(['none', 'flex'])
        })
    }

    return (
        <Container fluid className='admin-decorator'>
            <div style={{display: elementsDisplays[0]}}>
                <div className='admin-decorator__title'>
                    Изменить номер телефона
                </div>
                <div className="admin__add__field-block">
                    <div className="admin__add__field-title">
                        Введите номер телефона
                    </div>
                    <input id={phoneId} 
                        className="admin__add__field" 
                        value={phoneContent} 
                        type='text' 
                        placeholder="..."  
                        onChange={(e) => {handleChangePhone(e)}}/>
                </div>
                <div className="admin__add__button">
                    <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                        Сохранить
                    </button>
                </div>
            </div>
            <SuccessMessage 
                    title={'Номер телефона'} 
                    savedTitle={'сохранен'} 
                    departmentTitle={'Контакты сайта'} 
                    changedTitle={'контактов'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAlterPhone;