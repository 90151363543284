
import {useRef, useEffect, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import {AiOutlineMenu, AiFillHome} from 'react-icons/ai';
import {BsPersonFill, BsTelephoneOutboundFill, BsFillJournalBookmarkFill,
    BsChatRightText, BsLayoutTextSidebarReverse, BsFileEarmarkPerson, BsFillFileEarmarkTextFill, BsHandThumbsUpFill } from 'react-icons/bs';
import {useSelector} from 'react-redux';

import './AdminSidebar.scss';


const sidebarNavItems = [
    {
        textContent: 'Главная',
        icon: <AiFillHome/>,
        to: '/admin',
        section: ''
    },
    {
        textContent: 'Пункты меню',
        icon: <AiOutlineMenu/>,
        to: '/admin/menu',
        section: 'menu'
    },
    {
        textContent: 'Тексты страниц',
        icon: <BsFillFileEarmarkTextFill/>,
        to: '/admin/pagesTexts',
        section: 'pagesTexts'
    },
    {
        textContent: 'Анкеты',
        icon: <BsLayoutTextSidebarReverse/>,
        to: '/admin/forms',
        section: 'forms'
    },
    {
        textContent: 'Команда',
        icon: <BsFileEarmarkPerson/>,
        to: '/admin/members',
        section: 'members'
    },
    {
        textContent: 'Публикации',
        icon: <BsFillJournalBookmarkFill/>,
        to: '/admin/publicationSections',
        section: 'publicationSections'
    },
    {
        textContent: 'Полезные материалы',
        icon: <BsHandThumbsUpFill/>,
        to: '/admin/articles',
        section: 'articles'
    },
    {
        textContent: 'Контакты сайта',
        icon: <BsTelephoneOutboundFill/>,
        to: '/admin/contacts',
        section: 'contacts'
    },
    {
        textContent: 'Пользователи',
        icon: <BsPersonFill/>,
        to: '/admin/users',
        section: 'users'
    },
]

const AdminSidebar = () => {
    const authUser = useSelector(x => x.auth.user);
    const [activeIndex, setActiveIndex] = useState(0);
    const sidebarRef = useRef();
    const location = useLocation();
    
    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return <div className='sidebar'>
        <div className='sidebar__wrapper'>
            <Link to={'/'} className='sidebar__site-name'>
                Активное долголетие
            </Link>
            <div className="sidebar__person">
                <div className='sidebar__person__name'>
                    {authUser.lastName + " " + authUser.firstName + " " + authUser.patronymicName}
                </div>
                <div className='sidebar__person__role'>
                    {authUser.officialRole}
                </div>
            </div>
        </div>
        <div ref={sidebarRef} className="sidebar__menu">
            {
                sidebarNavItems.map((item, index) => (
                    <Link to={item.to} key={index} className='sidebar__menu__link'>
                        <div className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}>
                                <div className='sidebar__menu__item__icon'>{item.icon}</div>
                                {item.textContent}
                        </div>
                    </Link>
                ))
            }
        </div>
    </div>;
};

export default AdminSidebar;