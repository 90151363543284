
import { Draggable } from "react-beautiful-dnd";
import {AiOutlineMenu} from 'react-icons/ai';

import ChangeButtons from '../ChangeButtons';

import './StringWithChanges.scss';

const StringWithChanges = ({stringPoint, artificialId=0, classes=[], functionRemove, functionCancel, linkAlter, itemStatus}) => {

    return (
        <Draggable draggableId={String(stringPoint.id)} index={artificialId}>
          {(provided, snapshot) => {
            return (
                <div className={classes.join(' ')}
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div className='string-with-changes__icon-container'>
                        <AiOutlineMenu/>
                    </div>
                    <div className="string-with-changes">
                        <div>
                            {stringPoint.title}
                        </div>
                        <div>
                            <ChangeButtons 
                            itemId={stringPoint.id} 
                            artificialId={artificialId}
                            removeFunction={functionRemove} 
                            cancelFunction={functionCancel} 
                            linkAlter={linkAlter}
                            itemStatus={itemStatus}/>
                        </div>
                    </div>
                </div>
            );
        }}
    </Draggable>
    )
 }
 
 export default StringWithChanges;