
import {useState, useEffect} from "react";
import SaveButton from "../../SaveButton";
import QuestionChooseOption from '../QuestionChooseOption';

import './LastPageAdmin.scss';

const LastPageAdmin = ({currentStyle, currentFormId=null, data=null}) => {

    const [answersList, setAnswersList] = useState([{type: 1, classType: 1, data: ""}]);
    const [arrayOptions, setArrayOptions] = useState([]);
    const [loading, setLoading] = useState('Сохранить'); //текст кнопки сохранения

    const addAnswers = () =>  {
        setAnswersList(current => [...current, {type: 1, classType: 1, data: ""}]);
    }

    useEffect(() => {
        if(data != null && data.length !== 0) {
            setAnswersList([])
            setArrayOptions([])
            data.map((element, i) => { 
                setArrayOptions(current => [...current, {content: element.title, id: element.id}])
                setAnswersList(current => [...current, {id: element.id, type: 1, classType: 1, data: element.title}])
            })
        }
    }, [data])

    const createArrayOptions = (optionValue, id) => {
        if(arrayOptions.find(item => item.id===id)) {
            const updateOptions = arrayOptions.map((elem) => (
                elem.id !== id ? elem : {content: optionValue, id: id}
            ))
            setArrayOptions(updateOptions);
        }
        else {setArrayOptions(current => [...current, {content: optionValue, id: id}])}
    }

    const removeOption = (e, blockId) => {
        e.currentTarget.parentNode.remove()
        const optionArray = arrayOptions.filter(item => item.id !== blockId)
        setArrayOptions(optionArray)
    }

    const saveSuggestions = () => {
        if(data == null) {
            fetch(`${process.env.REACT_APP_API_URL}/addSuggestions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({suggestionsList: arrayOptions, formId: currentFormId}),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                });
                setLoading("Сохранено")
            })
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/editSuggestions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({suggestionsList: arrayOptions, formId: currentFormId}),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                });
                setLoading("Сохранено")
            })
        }
    }

    return (
        <div className='last-page-admin' style={currentStyle}>
            <div className='last-page-admin__title'>
                Предложения для анкетируемого
            </div>
            <div className="last-page-admin__block">
             {answersList.map((answer, i) => (
                        <div key={i}>
                            <div className="last-page-admin__block__string" style={{width: '90%'}}>
                                <QuestionChooseOption id={answer.id === undefined ? i : answer.id} 
                                    type={answer.type} 
                                    data={answer.data}
                                    blockWidth={500}
                                    classType={answer.classType} 
                                    checkFunction={createArrayOptions} 
                                    checkRemoveWord={'option'}
                                    removeAnswer={removeOption}
                                />
                            </div>
                        </div>)
                    )}
                    <div className='question-block__different-option'> 
                        <div className='question-block__different-option__add' onClick={() => {addAnswers()}} >Добавить вариант</div> 
                    </div>
                </div>
            <SaveButton 
            saveChanges={saveSuggestions} 
            responseText={loading}
            currentClassName={'active'}
            isCurrentDisabled={false}/>
        </div>
    )
}

export default LastPageAdmin;