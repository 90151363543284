
//функция проверки контента формы на ошибки
const CheckForm = (formTitle, formLogo) => {

        let objectResponse = {hasObjectsNeedRemake: false} //возвращаемый функцией объект 

        //проверка на пустоту массива
        const checkLength = (checkedObject=[]) => {
            return (checkedObject.length!==0) ? true : false
        }

        //проверка на пустоту содержания input
        const checkTitle = (titleObject) => {
            return (titleObject!==null) ? (titleObject.trim()!=='') ? true : false : false
        }

        //определение, есть ли в форме ошибки
        let hasProblems = false;
        if(checkTitle(formTitle)===false || checkLength(formLogo)===false) {
            hasProblems = true;
        }
        //инициализация возвращаемого объекта
        objectResponse = {hasObjectsNeedRemake: hasProblems}
        
    return objectResponse;
} 
export default CheckForm;