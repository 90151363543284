
import {Col} from "react-bootstrap";
import {Link} from 'react-router-dom';

import './FormCard.scss';

export const FormCard = ({item, linkAlter='/notFound'}) => {

	return (
        <Col xl={3} lg={4} md={6} sm={6} xs={12} className="form-card--wrapper">
            <Link to={linkAlter} className="form-card">
                <div className="form-card__top">
                    <div className="form-card__image--wrap">
                        <img className="form-card__image" src={item?.src} alt="tea"/>
                    </div>
                    <div className="form-card__name">
                        {item?.title}
                    </div>
                </div>
                <div className="form-card__text">
                    <div className="form-card__text--description">
                        {item?.description}
                    </div>
                </div>
            </Link>
        </Col>
	)
}

export default FormCard