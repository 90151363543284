import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {Container} from 'react-bootstrap';
import { history } from '../_helpers/history';
import { authActions } from '../_store';

import "./Login.scss";

const Login = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/admin/home');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Введите логин'),
        password: Yup.string().required('Введите пароль')
    });

    const formOptions = {resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    return (
        <Container fluid className='admin-access'>
            <div className="admin-access__main">
                <div className='admin-access__title'>
                    Авторизация
                </div>
                <div className='admin-access__title-text'>
                    Введите ваши учетные данные
                </div>
                <div className="admin-access__form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <div className="admin-access__form__field-name">Логин</div>
                            <input name="username" type="text" {...register('username')} className={`admin-access__form__field ${errors.username ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="form-group">
                        <div className="admin-access__form__field-name">Пароль</div>
                            <input name="password" type="password" {...register('password')} className={`admin-access__form__field ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <div className="admin-access__form__button-container">
                            <button disabled={isSubmitting} className="admin-access__form__button">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1 admin-access__form__button-focused"></span>}
                                Войти
                            </button>
                        </div>
                        {authError &&
                            <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                        }
                    </form>
                </div>
            </div>
        </Container>
    )
}
export default Login