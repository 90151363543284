import * as React from 'react'

import {Container} from 'react-bootstrap';
import {useState, useLayoutEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import '../AdminPage.scss'
import '../AdminAdd/AdminAdd.scss';
import SuccessMessage from '../../../components/UI/SuccessMessage';

const AdminAlterText = () => {

    let {textId} = useParams();
    const authUser = useSelector(x => x.auth.user);
    const agreeCondition = /^agree/;

    const [currentId, setCurrentId] = useState(0)
    const [postText, setPostText] = useState("alterText")
    const [elementsDisplays, setElementsDisplays] = useState(['block', 'none'])
    const [textContent, setTextContent] = useState({title: '', content: ''})

    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useLayoutEffect(() => {
        const condition = agreeCondition.test(textId);
        if(condition) {
            const changedTextId = textId.split(agreeCondition)
            setCurrentId(Number(changedTextId[1]))
            fetch(`${process.env.REACT_APP_API_URL}/getAgreePlainText`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(textId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    setTextContent({title: "Согласие на обработку персональных данных", content: data.content})
                    setPostText("alterAgreeText")
                })
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            });
        }
        else {
            setCurrentId(Number(textId))
            fetch(`${process.env.REACT_APP_API_URL}/getTextById`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Number(textId)),
            }).then((response) => {
                response.text().then(text => {
                    const data = text && JSON.parse(text);
                    setTextContent({title: data.title, content: data.textContent})
                })
                setAppState({ loading: false, repos: response});
            }).catch(function(error) {
                console.log(error)
            });
        }
    }, [setAppState]) 

    const handleChange = (e) => {
        const currentContentValue = e.target.value;
        setTextContent({title: textContent.title, content: currentContentValue})
    }

    const acceptChanges = () => {
        const textResponse = {id: currentId, content: textContent.content, textAuthor: authUser.id}
         fetch(`${process.env.REACT_APP_API_URL}/${postText}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(textResponse),
        }).then(result => {
            setElementsDisplays(['none', 'block'])
        })
    }

    return (
        <Container fluid className='admin-decorator'>
            <div style={{display: elementsDisplays[0]}}>
                <div className='admin-decorator__title'>
                    Изменить "{textContent.title}"
                </div>
                <div>
                    <textarea id={currentId} 
                    className="admin__add__field admin__add__field__large" 
                    value={textContent.content} 
                    type='text' 
                    placeholder="..."  
                    onChange={(e) => {handleChange(e)}}/>
                </div>
                <div className="admin__add__button">
                    <button className='admin__button admin__add__button__changed' onClick={() => {acceptChanges()}}>
                        Сохранить
                    </button>
                </div>
            </div>
            <SuccessMessage 
                    title={'Текст страницы'} 
                    savedTitle={'сохранен'} 
                    departmentTitle={'Тексты страниц'} 
                    changedTitle={'текста'} 
                    displayStyle={elementsDisplays[1]}/>
        </Container>
    )
}

export default AdminAlterText;