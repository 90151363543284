import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './colors.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../src/_store/index';
import './fonts/Nunito-Bold.ttf';
import './fonts/Nunito-Light.ttf';
import './fonts/Nunito-Regular.ttf';
import './fonts/Nunito-Medium.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
);

reportWebVitals();
