
import './SuccessMessage.scss';

const SuccessMessage = ({title, savedTitle, departmentTitle, changedTitle, displayStyle}) => {

     return (
        <div className="successful" style={{display: displayStyle}}>
            {title} успешно {savedTitle}!
            <br/>
            Чтобы вернуться к правке {changedTitle}, перейдите в раздел "{departmentTitle}"
        </div>
     )
 }
 
 export default SuccessMessage;